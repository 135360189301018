import { showStyles } from "./ShowListCss";
import { Paper } from "@mui/material";
import img from "../../assets/comingsoon.jpg"
 export default function EInviteList(props){

    return(<Paper elevation={2} style={{display:props?.isOpen?'flex':'none',position:'absolute',top:50,zIndex:3,marginLeft:400,cursor:'pointer',width:'50%',height:'500%'}}>


<img src={img} style={{width: '100%',height: '100%;'}}/>

    </Paper>


    )
 }