import { Grid, useMediaQuery, Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import venuestar from "../assets/venuestar.webp";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getData } from "../Services/FetchNodeServices";
import { useNavigate } from "react-router-dom";

export default function CategoryCard(props) {
    const listing = props.item || [];  // Ensure listing is an array even if not provided
    const SubcategoryId = props?.subcategoryid;  // Use the subcategory ID directly
    const [subCategoryData, setSubcategoryData] = useState([]);
    var navigate=useNavigate()
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

    const fetchSubcategoryByFilter = async () => {
        if (SubcategoryId) {
            const result = await getData(`api-listing?subcategory_id=${SubcategoryId}`);
            if (result.status) {
                setSubcategoryData(result.data);
            }
        }
    };

    useEffect(() => {
        fetchSubcategoryByFilter();  // Fetch filtered subcategory data based on SubcategoryId
    }, [SubcategoryId]);

    // const handleProductDetail=(item)=>{
    //     navigate(`/cart/${item.id}`,{state:{data:item}})
    
    //   }

    const handleProductDetail = (item) => {
        localStorage.setItem("cartItem", JSON.stringify(item));
        navigate(`/cart/${item.id}`);
    };
    



    const renderVenueCard = (data) => {
        return data?.map((item, index) => (
            <Grid
                item
                xs={12} sm={3}
                key={item.id}
                onClick={() => handleProductDetail(item)} 
                style={{ display: 'flex', justifyContent: 'center', height: matchesMd ? 'auto' : 360, aspectRatio: matchesMd ? 'auto' : 2 / 2, paddingBottom: matchesMd ? 5 : 1, marginTop: matchesMd ? 'auto' : 20 }}
            >
                <Paper elevation={3} sx={{ padding: 1 }} style={{ height: matchesMd ? 'auto' : 'auto', width: matchesMd ? 'auto' : 300 }}>
                    <Grid item xs={12}>
                        <img
                            src={item.main_photo}
                            alt="Portfolio"
                            style={{ width: '100%', height: matchesMd ? 350 : 150, cursor: 'pointer', objectFit: 'cover' }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ fontFamily: 'kanit', display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 15, fontWeight: 'bold' }}>{item.name}</span>
                        <span>
                            <img src={venuestar} style={{ width: 13, height: 13, marginRight: 6 }} alt="Star" />5.0
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{ marginLeft: 3, fontSize: 13 }}>({item.Total_review} review)</span>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}>
                        <span><LocationOnIcon style={{ alignItems: 'center', marginTop: 5 }} fontSize="small" /></span>
                        <span style={{ alignItems: 'center', fontSize: 14, justifyContent: 'center', display: 'flex' }}>{item.city}</span>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                        <span><AccountBalanceIcon style={{ alignItems: 'center', marginRight: 4 }} fontSize="small" /></span>
                        <span style={{ fontSize: 13, lineHeight: 1.2 }}>{item.address}</span>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                        <span><CurrencyRupeeIcon fontSize="small" /></span>
                        <span style={{ fontSize: 14 }}> {item.main_price} / {item.main_price_name}</span>
                    </Grid>
                </Paper>
            </Grid>
        ));
    };

    return (
        <div style={{ width: '100vw', height: 'auto', overflowX: 'hidden', fontFamily: 'kanit', paddingBottom: matchesMd ? 'auto' : 6 }}>
            <Box>
                <Grid container spacing={1}>
                    {/* Render subcategory data if SubcategoryId is present, otherwise render the listing */}
                    {SubcategoryId ? renderVenueCard(subCategoryData) : renderVenueCard(listing)}
                </Grid>
            </Box>
        </div>
    );
}
