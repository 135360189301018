import { Grid,Paper,Divider } from "@mui/material"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import { useState,useEffect } from "react";
import { getData,postData} from "../../Services/FetchNodeServices";
import React from "react";

export default function AreasAvailableComponent(props){
   
   var data=props.item
  

   
   const theme = useTheme();

   
   const [areasInfo, setAreasInfo] = useState([]);
const matchesMd=useMediaQuery(theme.breakpoints.down("md"));







useEffect(() => {
   if (data) {
      setAreasInfo(data?.areas_info || []);
     
   }
}, [data]);


  
  
 return(<Paper style={{width:matchesMd?'100vw': 700,height:'auto',padding:10,marginLeft:matchesMd?<></>:15,paddingLeft:matchesMd?<></>:10,fontFamily:'kanit'}}>
  

  <Grid item xs={12} style={{display:'flex', fontSize:18,marginLeft:matchesMd?5:25,marginTop:12,fontWeight:'bold',padding:3,marginBottom:10}}>
       Areas Available  ({areasInfo.length})
    </Grid>

    <Grid item xs={12} >
       <Divider/>
    </Grid>

  {areasInfo.map((area, index) => (
    <React.Fragment key={index}>
  <Grid container spacing={3}>

    

   



    
              
    
    <Grid item xs={2} style={{display:'flex',alignItems:'center'}}>
      
        <div ><img src={area.imge} style={{width:30,height:30,borderRadius:'60%',display:'flex',alignItems:'center',marginTop:23}}/></div>


        <div style={{fontSize:12,marginLeft:10,display:'flex',fontWeight:'bold',marginTop:23}}>{area.area_type}</div>
        


    </Grid>

   



     
  
    
    <Grid item xs={10}  style={{alignItems:'center',paddingLeft:matchesMd?60:<></>}}>
       {/* <div>{portfolioData && <div>{portfolioData.areas_info[0]?.area_subtitle}</div>}</div> */}
       <div style={{fontWeight:'bold',display:'flex',alignItems:'center',marginTop:24}}>{area.area_title}</div>
       <div style={{fontSize:14,lineHeight:2}}>{area.area_subtitle}</div>

    </Grid>
    <Grid item xs={12} >
       <Divider/>
    </Grid>



  </Grid>

  </React.Fragment>
            ))}





    </Paper>)
}