import { Grid, Paper } from "@mui/material";
import Header from "../Component/userinterface/Header";
import UpperHeader from "../Component/userinterface/UpperHeader";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Footer from "../../src/Component/userinterface/Footer";
import ArticleBreadcrump from "../Component/userinterface/ArticleBreadcrump";
import { getData, postData } from "../Services/FetchNodeServices";
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { fadeIn } from '../Component/userinterface/Variants';

export default function Article() {
    const theme = useTheme();
    const [article, setArticle] = useState([]);
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

    const fetchArticles = async () => {
        const result = await getData('api-story');
        if (result.status) {
            setArticle(result.data);
        }
    };

    const createSession = async () => {
        const body = { email: "9907026112", password: "12345678" };
        const result = await postData("login", body);
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token);
        }
    };

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            createSession().then(() => {
                fetchArticles();
            });
        }
        fetchArticles();
    }, []);

    return (
        <div style={{ overflowX: 'hidden', background: "white" }}>
            {matchesMd ? null : (
                <Grid item xs={12}>
                    <motion.div variants={fadeIn('down', 0.2)} initial="hidden" animate="show">
                        <UpperHeader />
                    </motion.div>
                </Grid>
            )}

            <Grid item xs={12}>
                <motion.div variants={fadeIn('down', 0.3)} initial="hidden" animate="show">
                    <Header />
                </motion.div>
            </Grid>

            <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginBottom: '19px' }}>
    <motion.div style={{ width: '100%' }} variants={fadeIn('up', 0.4)} initial="hidden" animate="show">
        <ArticleBreadcrump />
    </motion.div>
</Grid>

{article?.map((item, index) => (
    <Grid item xs={12} md={6} key={index}>
        <motion.div
            // Apply conditional animation direction based on the index
            variants={fadeIn(index % 2 === 0 ? 'right' : 'left', 0.5 + index * 0.2)}
            initial="hidden"
            animate="show"
        >
            <Grid item xs={12} style={{ marginTop: matchesMd ? null : 20, display: 'flex', justifyContent: 'center' }}>
                <Paper style={{
                    width: matchesMd ? '100vw' : 700,
                    height: 'auto',
                    padding: matchesMd ? 10 : null,
                    display: 'flex',
                    margin: matchesMd ? null : 10,
                    fontFamily: 'kanit'
                }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <img src={item?.main_photo} style={{ width: '100%' }} alt="Article" />
                        </Grid>

                        <Grid 
  item 
  xs={12} 
  style={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', // Center content horizontally
    marginTop: '-28px', 
    textAlign: 'center' 
  }}
>
  <h2 
    style={{ 
      fontFamily: 'Playfair Display, Helvetica Neue, Helvetica, Arial, sans-serif', 
      color: '#ee5b64', 
      padding: 5 
    }}
  >
    {item?.title}
  </h2>
</Grid>

                        <Grid item xs={12}>
                            <h5 style={{ color: 'grey', textAlign: "center", marginTop: '-29px' }}>{item?.subtitle}</h5>
                        </Grid>

                        <Grid 
  item 
  xs={12} 
  style={{ 
    fontSize: 24, 
    color: 'grey', 
    fontWeight: 'bolder', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', // Center content horizontally
    textAlign: 'center', 
    marginBottom: '15px', 
    marginTop: '-22px' 
  }}
>
  {item?.date}
</Grid>

                    </Grid>
                </Paper>
            </Grid>
        </motion.div>
    </Grid>
))}

                
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <motion.div variants={fadeIn('up', 1)} initial="hidden" animate="show">
                        <Footer />
                    </motion.div>
                </Grid>
            </Grid>
        </div>
    );
}
