// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth"
const firebaseConfig = {
  apiKey: "AIzaSyB3ijfsJP8O0DhTEqtW5ok4HhXdT58pbQw",
  authDomain: "wedmegood-a0323.firebaseapp.com",
  projectId: "wedmegood-a0323",
  storageBucket: "wedmegood-a0323.appspot.com",
  messagingSenderId: "195909505500",
  appId: "1:195909505500:web:b1f8f60fc0feb1acbeff19",
  measurementId: "G-VVRZR5FTC5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth(app);
const provider= new GoogleAuthProvider()
export{auth,provider};