import { Grid ,Paper} from "@mui/material"
import {FormControl,Select,MenuItem,InputLabel,useMediaQuery} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import { useTheme } from "@mui/material/styles";
import bgImg from "../../src/assets/searchimage3.jpg"
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';

export default function SearchingPage(){
    const theme = useTheme();
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));


    const searchBarComponent=()=>{
        return (
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', margin:1, alignItems: 'center', width: '90%',padding:'7px',borderRadius:30}}
          >
           
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Products Here..."
              inputProps={{ 'aria-label': 'search google maps' }}
            
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon  />
            </IconButton>
            
          </Paper>
        );
    
      }

    return(<div style={{position:'relative'}}>
        <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
         <img src={bgImg}
         style={{width:matchesMd?'100%':'100vw',height:matchesMd?'90%':'100vh',display:"flex",justifyContent:'center',alignItems:'center'}}/>

<div style={{zIndex:2,top:'21%', position:'absolute',display:'flex',color:'#fff',fontSize:28,fontWeight:'bold',fontFamily:'kanit',marginLeft:42}}>
         
               Everything you need, to plan your dream Wedding!
                 
                  
        </div>
     

      
         
        <div style={{zIndex:2,top:'28%', position:'absolute',display:'flex',color:'#fff',fontSize:18,marginLeft:20}}>
         
        Search for vendors, ideas, real wedding stories and more!
                 
                  
        
     
           
            
  </div>

         <div style={{zIndex:2,top:'40%', position:'absolute',display:'flex',alignItems:'center',justifyContent:'center',background:'transparent',width:"50%"}}>
         
        {searchBarComponent()}
                
                 
       </div>
</Grid>

    </div>)





}