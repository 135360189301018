import { postData,getData } from "../Services/FetchNodeServices";
import { useState,useEffect } from "react";


export default function AppTesting(){
    const [loading, setLoading] = useState(false);
    const[catgoryData,setCategoryData]=useState([])
  const [isShimmer, setIsShimmer] = useState(true);

    const fetchAllCategory = async () => {
        setLoading(true);
        const result = await getData('api-listing-filter?subcategory_id=1')
        if (result.status) {
         
          setCategoryData(result.data)
          setIsShimmer(false)
         
           // console.log(result.data)
        }
        setLoading(false);
    }
  
  const createSession = async () => {
        setLoading(true)
        const body = { email: "9907026112", password: "12345678" }
        const result = await postData("login", body)
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token)
        }
        setLoading(false);
    }
    
    useEffect(() => {
      if (!localStorage.getItem("token")) {
          createSession().then(() => {
            fetchAllCategory()
          })
      }
      fetchAllCategory()
  }, [])
  

  
  
  const fillAllSubCategory = () => {
    return catgoryData?.map((item) => {
      return <div>{item.optionType}{item.id} {item.name} </div>;
     
    });
  };
  

    return(<div>

     {fillAllSubCategory()}
   
    </div>)
}