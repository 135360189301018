import makeStyles from '@mui/styles/makeStyles';
export const showStyles = makeStyles({
    root:{
        width:'300',
        height:'auto',
        display:'flex',
        flexDirection:'column',
        padding:5
       
    },

    heading:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:10
    },

    order:{
        fontSize:16,
        fontWeight:'bold',
        color:'#e72e77',
    },

    item:{
        fontSize:16,
        fontWeight:'bold',
        color:'#e72e77',
    },

    product:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:6
    },

   









});