import React, { useState, useEffect } from 'react';
import { Grid, Paper, Box, Typography, Button, Backdrop } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import YouTube from 'react-youtube';
import Lottie from 'lottie-react';
import loader from '../../loader.json';
import { getData, postData } from '../../Services/FetchNodeServices';

export default function VideoGallery() {
    const [open, setOpen] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [player, setPlayer] = useState(null);

    const fetchAllData = async () => {
        setLoading(true);
        const result = await getData('api-listing/1');
        if (result.status) {
            setPortfolioData(result.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            createSession().then(() => {
                fetchAllData();
            });
        } else {
            fetchAllData();
        }
    }, []);

    const createSession = async () => {
        setLoading(true);
        const body = { email: "9907026112", password: "12345678" };
        const result = await postData("login", body);
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token);
        }
        setLoading(false);
    };

    const handleOpenDialog = (index) => {
        setSelectedVideoIndex(index);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleNext = () => {
        if (selectedVideoIndex < portfolioData?.videos_info?.length - 1) {
            setSelectedVideoIndex(selectedVideoIndex + 1);
        }
    };

    const handlePrev = () => {
        if (selectedVideoIndex > 0) {
            setSelectedVideoIndex(selectedVideoIndex - 1);
        }
    };

    const opts = {
        height: '250',  // Set the height of the YouTube video
        width: '100%',  // Set the width of the YouTube video to match the Grid item width
        playerVars: {
            autoplay: 0,
        },
    };

    const onReady = (event) => {
        setPlayer(event.target);
    };

    const handlePlayPause = () => {
        if (player) {
            if (player.getPlayerState() === 1) { // Playing
                player.pauseVideo();
            } else {
                player.playVideo();
            }
        }
    };

    const handleMuteUnmute = () => {
        if (player) {
            const isMuted = player.isMuted();
            if (isMuted) {
                player.unMute();
            } else {
                player.mute();
            }
        }
    };

    const handleVolumeChange = (event) => {
        if (player) {
            player.setVolume(event.target.value);
        }
    };

    const handleClickDialog = () => {
        if (!portfolioData || !portfolioData.videos_info || portfolioData.videos_info.length === 0) {
            return null;
        }

        return (
            <Dialog
                maxWidth={"lg"}
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    <div style={{ fontSize: 25, fontWeight: 'bold', fontFamily: 'kanit', color: '#e72e77', padding: 25 }}>
                        Videos Player
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ zIndex: 2, top: '50%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50, height: 50, borderRadius: 20, opacity: 0.6 }}>
                        <ArrowBackIosIcon fontSize="large" onClick={handlePrev} />
                    </div>

                    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                        <YouTube
                            videoId={portfolioData?.videos_info[selectedVideoIndex]?.video_link}
                            opts={opts}
                            onReady={onReady}
                        />
                        <div style={{ position: 'absolute', bottom: 20, left: 20 }}>
                            <Button onClick={handlePlayPause}>Play/Pause</Button>
                            <Button onClick={handleMuteUnmute}>Mute/Unmute</Button>
                            <input type="range" min="0" max="100" defaultValue="100" onChange={handleVolumeChange} style={{ marginLeft: 10 }} />
                        </div>
                        <div style={{ zIndex: 2, top: '50%', right: '2.7%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 20, opacity: 0.6 }}>
                            <ArrowForwardIosIcon fontSize="large" onClick={handleNext} />
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div>
            <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    {portfolioData?.videos_info?.map((video, index) => (
                        <Grid
                            item
                            xs={12} sm={6} md={3}
                            key={video.id}
                            onClick={() => handleOpenDialog(index)}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', height: '250px', width: '100%' }}
                        >
                            <Paper elevation={3} sx={{ padding: 1, width: '100%' }}>
                                <YouTube
                                    videoId={video?.video_link}
                                    opts={opts}
                                    style={{ cursor: 'pointer', objectFit: 'cover', width: '100%', height: '100%' }}
                                />
                                <Typography variant="subtitle1" gutterBottom>{video.title}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {handleClickDialog()}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div style={{ width: '20%' }}>
                    <Lottie loop={true} animationData={loader} />
                </div>
            </Backdrop>
        </div>
    );
}
