import { Grid,Paper,Divider} from "@mui/material"
import { useMediaQuery } from "@mui/material";
import {useTheme} from "@mui/material";
import orderhere from "../../assets/orderhere.png"

export default function PreviousOrders(){
    const theme = useTheme();
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));
   
    return(<div style={{width:'100%',height:'auto',fontFamily:'kanit'}}>
        <Grid container spacing={2}>
            <Grid item xs={12} style={{fontSize:matchesMd?'0.9em':23,color:'#e72e77',fontWeight:'bold',display:'flex',justifyContent:'center'}}>
                <span><img src={ orderhere} style={{width:150,height:70,borderRadius:200}}/></span>
            </Grid>

            <Grid item xs={12} >
                <Divider/>
            </Grid>

           


        </Grid>



    </div>)
}