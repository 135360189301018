import { Grid, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons"; // Importing social icons
import { faMapMarkerAlt,faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo2.png";
import photo from '../../assets/fb.webp';
import { getData,postData } from "../../Services/FetchNodeServices";
import { useState,useEffect } from "react";

export default function Footer() {
  const theme = useTheme();
  var navigate=useNavigate()
  const[catgoryData,setCategoryData]=useState([])
  const[venueItem,setVenueItem]=useState(false)
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const fetchPortfolio = async () => {
   
    const result = await getData('api-category')
    if (result.status) {
     
      setCategoryData(result.data)
     
     
       
    }
   
}

const createSession = async () => {
 
  const body = { email: "9907026112", password: "12345678" }
  const result = await postData("login", body)
  if (result.token_status) {
      localStorage.setItem("token", result.data.user.jwt_token)
  }
 
}

useEffect(() => {
  if (!localStorage.getItem("token")) {
      createSession().then(() => {
        fetchPortfolio()
      })
  }
  fetchPortfolio()
}, [])


  const imagesToShow = catgoryData.slice(0,6)
  const venueShow = catgoryData.slice(17)
 
  const handleNavigateSubcategory=(item)=>{
    navigate('/shopscreen',{state:{data:item}})
    localStorage.setItem("category", item.id)
   
   
  }
  
  



  

 
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        justifyContent: "flex-start",
        display: "flex",
        fontFamily: "kanit",
        padding: matchesMd ? 5 : 10,
        backgroundImage: `url("${photo}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#fff",
      }}
    >
      <Grid container spacing={0}>

      
       
      
         
            <Grid item md={3} xs={12} style={{alignItems:'center'}}>
              <div>
                <img src={logo} style={{ width: '100%',alignItems:'center' }} alt="logo" />
              </div>

              {/* Social Media Icons in a Row */}
              <div style={{ display: "flex", justifyContent: "center", gap: '44px', marginTop: '5px' }}>
                <FontAwesomeIcon icon={faFacebook} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px' }} />
                <FontAwesomeIcon icon={faInstagram} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px' }} />
                </div>
                <div  style={{ display: "flex", justifyContent: "center",gap:'44px', marginTop: '20px'  }}>
                <FontAwesomeIcon icon={faTwitter} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px' }} />
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px' }} />
                </div>
            </Grid>

            
            <Grid item md={3} xs={12} style={{alignItems:'center'}}>


           

              <div style={{ color: 'rgb(231, 46, 119)',fontWeight:'bolder' ,justifyContent:'center',fontSize:'25px',marginLeft:matchesMd?'39%':'50%',marginTop:matchesMd?'41px':'10px'}}>Explore</div>
             

              <div onClick={()=>navigate('/home')} style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              Home
              </div>

              {venueShow?.map((item, index) => (
              <div  onClick={()=>handleNavigateSubcategory(item)} style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              {item.name}
              </div>
                ))
              }

              <div style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              Vendors
              </div>

              <div style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              Shop
              </div>

              <div style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              E-Invites
              </div>

              <div  onClick={()=>navigate('/article')} style={{color:'rgb(231, 46, 119)',marginLeft:matchesMd?'42%':'53%',marginTop:'10px',cursor:'pointer'}}>
              Articles
              </div>
              

           
           
          </Grid>

            <Grid item md={2} xs={12} style={{alignItems:'center'}}>
              <div style={{ color: 'rgb(231, 46, 119)',fontWeight:'bolder' ,justifyContent:'center',fontSize:'25px',marginLeft:'35%',marginTop:matchesMd?'41px':'10px'}}>Categories</div>

              {imagesToShow?.map((item, index) => (
            

           
                        
                    
                        <div  onClick={()=>handleNavigateSubcategory(item)} style={{color:'rgb(231, 46, 119)',marginLeft:'38%',marginTop:'10px',cursor:'pointer'}}>
              {item.name}
              </div>
                   
            
                    
            
                  
            
            ))
            
           
            
            
            
                           
                           
                            }



            

           
           
          </Grid>

        
       

        <Grid item xs={12} md={4} style={{ color: "#fff" }}>
        <div style={{ color: 'rgb(231, 46, 119)',fontWeight:'bolder' ,justifyContent:'center',fontSize:'25px',marginLeft:'31%',marginTop:matchesMd?'41px':'10px'}}>Contact Info</div>
          <div style={{display:'flex',marginLeft:matchesMd?'6%':'31%',marginTop:'40px'}}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px',marginRight:'10px' }} />
          Second Floor, Ocus Technopolis, Sector 54 Golf
            </div>

            <div style={{display:'flex',marginLeft:matchesMd?'6%':'31%',marginTop:'40px'}}>
            <FontAwesomeIcon icon={faPhone} style={{ color: 'rgb(231, 46, 119)', fontSize: '24px',marginRight:'10px' }} /> {/* Phone icon */}
          899998899998
            </div>

            <div style={{display:'flex',marginLeft:matchesMd?'6%':'31%',marginTop:'40px',marginBottom:'30px'}}>
            <FontAwesomeIcon icon={faEnvelope}  style={{ color: 'rgb(231, 46, 119)', fontSize: '24px',marginRight:'10px' }}/> {/* Message (envelope) icon */}
          xyz@gmail.com
            </div>
          
          
         
        </Grid>
      </Grid>
    </div>
  );
}
