import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createRef, useEffect, useState } from "react";
import logo from "../../assets/logo.webp";
import pic from "../../assets/pic.webp";
import { getData, postData, serverURL } from "../../Services/FetchNodeServices";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { Skeleton } from "@mui/material";

// Slide fade animation
const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(1.02); }
  100% { opacity: 1; transform: scale(1); }
`;

// Styled components for the slider and arrows
const StyledSlider = styled(Slider)`
  .slick-slide img {
    animation: ${fadeIn} 0.7s ease-in-out;
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.05); // zoom-in effect on hover
    }
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #95a5a6;
  opacity: 0.6;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

export default function PopularSearches(props) {
  const [banner, setBanner] = useState([]);
  const sld = createRef();
  const theme = useTheme();
  const [isShimmer, setIsShimmer] = useState(true);
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    arrows: false,
    centerMode: false,
  };

  const handleForward = () => {
    sld.current.slickPrev();
  };

  const handleBackward = () => {
    sld.current.slickNext();
  };

  const showBrandSlideShimmer = () => {
    return ["", "", "", "", "", "", ""].map((_, index) => (
      <div key={index}>
        <Skeleton
          variant="rectangular"
          height={matchesSm ? 200 : 545}
          width="100%"
          animation="wave"
          style={{
            borderRadius: 20,
            boxShadow: "1px 1px 10px 0px #00000010",
          }}
        />
      </div>
    ));
  };

  const showSlide = () => {
    return banner.map((item, index) => (
      <div key={index}>
        <img
          src={item.photo}
          alt={`Slide ${index}`}
          style={{
            width: "100%",
            height: matchesSm ? 200 : 545,
            display: "block",
            borderRadius: 20,
            objectFit: "cover",
          }}
        />
      </div>
    ));
  };

  const fetchPopularSearches = async () => {
    const result = await getData("api-banner");
    if (result.status) {
      setBanner(result.data);
      setIsShimmer(false);
    }
  };

  const createSession = async () => {
    const body = { email: "9907026112", password: "12345678" };
    const result = await postData("login", body);
    if (result.token_status) {
      localStorage.setItem("token", result.data.user.jwt_token);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      createSession().then(() => {
        fetchPopularSearches();
      });
    }
    fetchPopularSearches();
  }, []);

  return (
    <div style={{ width: "100%", position: "relative", height: matchesSm ? 200 : 545 }}>
      {!matchesMd && (
        <ArrowContainer style={{ left: "1%" }} onClick={handleBackward}>
          <ArrowBackIosIcon />
        </ArrowContainer>
      )}

      <StyledSlider {...settings} ref={sld}>
        {banner.length ? showSlide() : showBrandSlideShimmer()}
      </StyledSlider>

      {!matchesMd && (
        <ArrowContainer style={{ right: "1%" }} onClick={handleForward}>
          <ArrowForwardIosIcon />
        </ArrowContainer>
      )}
    </div>
  );
}
