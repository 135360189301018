import { Grid,Paper } from "@mui/material"
import star from "../../assets/star.png"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import { useState ,useEffect} from "react";
import { getData,postData,serverURL } from "../../Services/FetchNodeServices";
import StarsIcon from '@mui/icons-material/Stars';



export default function CartImageDetails(props){
    const theme = useTheme();
    const [portfolioData, setPortFolioData] = useState([]);
    const [loading,setLoading]=useState(false)
    const [isGreenHovered, setIsGreenHovered] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    
    const [item, setItem] = useState(() => {
      const storedItem = localStorage.getItem("cartItem");
      return storedItem ? JSON.parse(storedItem) : null;
  });
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    const fetchAllData = async () => {
        setLoading(true);
        const result = await getData('api-listing/1')
        if (result.status) {
            setPortFolioData(result.data)
         
        }
        setLoading(false)
    }
    
    useEffect(() => {
        
      if (!localStorage.getItem("token")) {
          createSession().then(() => {
              fetchAllData()
          })
      }
      fetchAllData()
    }, [])
    
      
    
      const createSession = async () => {
        setLoading(true)
          const body = { email: "9907026112", password: "12345678" }
          const result = await postData("login", body)
          if (result.token_status) {
              localStorage.setItem("token", result.data.user.jwt_token)
          }
          setLoading(false)
      }



     

    

    return(< Paper style={{width:matchesMd?'100vw':700,height:matchesMd?'auto':'auto',padding:matchesMd?10:<></>,display:'flex',marginLeft:matchesMd?<></>:10,paddingLeft:matchesMd?<></>:10,fontFamily:'kanit'}}>
        <Grid container spacing={2}>
            <Grid item xs={8}>

                <span style={{fontSize:25,fontWeight:'bold',display:'flex ',marginLeft:matchesMd?<></>:5,alignItems:'center',marginTop:20,display:'flex'}}>{item?.name} </span>
                
                {/* <span style={{width:60,height:60,background:'#3e8e41',color:'#fff',fontWeight:'bold',marginLeft:'left',display:'flex'}}> <img src ={star } style={{width:10,height:10}}/> 5.0 </span> */}
            </Grid>

            <Grid item xs={4} style={{display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:10}}>
            <div  style={{width:matchesMd?'45%':'30%',height:matchesMd?<></>:'65%',color:'#fff',borderRadius:3,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)',marginRight:40}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}><StarsIcon fontSize="small"/><span style={{fontSize:15,padding:3}} >{item?.Total_review}</span> </div>

            </Grid>

            <Grid item xs={12}>
               <span style={{display:'flex',alignItems:'center',fontSize:17}}> <LocationOnIcon fontSize="small"/> {portfolioData?.listing_data?.city}, {item?.state} <span style={{fontSize:13,color:'grey',alignItems:'center',display:'flex', marginLeft:5}}>(View On Map)</span> </span>
             
             </Grid>

             <Grid item xs={12} style={{fontSize:15,color:'grey',alignItems:'center',display:'flex',marginLeft:matchesMd?10:20}}>
             {item?.address}
             </Grid>

             <Grid 
      item 
      xs={12} 
      style={{ 
        color: '#3e8e41', 
        display: 'flex', 
        marginLeft: matchesMd ? 5 : 0 
      }}
    >
      <LocalPhoneIcon fontSize="small" />  
      <span 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          fontSize: 17, 
          marginLeft: matchesMd ? 3 : 1, 
          fontWeight: 'bold' 
        }}
      >
        {userDetails ? item?.main_contact_no : "contact"}
      </span> 
    </Grid>

             {matchesMd?<></>:

             <Grid item xs={12} style={{display:'flex',fontSize:14,padding:20}} >
             <span style={{display:'flex',alignItems:'center',marginLeft:15,marginRight:15}}><ImageOutlinedIcon fontSize="small" /><span style={{marginLeft:10}}> 12 Photos</span></span>|
             <span style={{display:'flex',alignItems:'center',marginLeft:15 ,marginRight:15}}><FavoriteBorderOutlinedIcon fontSize="small" /><span style={{marginLeft:10}}>Shortlist</span></span>|
             <span style={{display:'flex',alignItems:'center',marginLeft:15 ,marginRight:15}}><CreateOutlinedIcon fontSize="small" /> <span style={{marginLeft:10}} >Write a Review</span></span>|
             <span style={{display:'flex',alignItems:'center',marginLeft:15 ,marginRight:15}}><ShareOutlinedIcon fontSize="small" /> <span style={{marginLeft:10}}>Share</span></span>
             </Grid>
               }







        </Grid>
        


    </Paper>)




}