import Backdrop from '@mui/material/Backdrop';
import loader from '../../src/loader.json'
import Lottie from "lottie-react";
import { Grid,Divider ,useMediaQuery,Box,Paper} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import UpperHeader from '../Component/userinterface/UpperHeader';
import Header from '../Component/userinterface/Header';
import { useState,useEffect } from 'react';
import { getData,postData } from '../Services/FetchNodeServices';
import venuestar from "../../src/assets/venuestar.webp"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import UpperFilter from '../Component/userinterface/UpperFilter';



export default function WeddingAllVenues(){
    const [portfolioData, setPortFolioData] = useState([]);
    const [loading, setLoading] = useState(false);
    const[isShimmer,setIsShimmer]=useState(true)
    const[product,setProduct]=useState(location?.state?.data)
    
    var theme=useTheme()
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"))

    var location=useLocation()
    var venuecard=location?.state?.data
   

    var navigate=useNavigate()


    
   

    const fetchPortfolio = async () => {
        setLoading(true);
        const result = await getData('api-listing?type=venue')
        if (result.status) {
            setPortFolioData(result.data)
            setIsShimmer(false)
           // console.log(result.data)
        }
        setLoading(false);
    }
    
    
    
    const createSession = async () => {
        setLoading(true)
        const body = { email: "9907026112", password: "12345678" }
        const result = await postData("login", body)
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token)
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            createSession().then(() => {
                fetchPortfolio()
            })
        }
        fetchPortfolio()
    }, [])

    useEffect(() => {
        if (!product) {
            fetchPortfolio();
        }
    }, [product]);

    const handleProductDetail=(item)=>{
        navigate('/cart',{state:{data:item}})
    
      }


       const renderVenueCard = () => {
        return  portfolioData?.map((item,index) => (
        <Grid 
            item 
            xs={12} sm={4}
            key={item.id} 
            style={{ display: 'flex', justifyContent: 'center', width: '90%', height: matchesMd ? 'auto' : 600, aspectRatio:matchesMd?<></> :2 / 2, marginTop:matchesMd?<></>:5,paddingBottom:matchesMd?5:20}}
        >
            <Paper elevation={3} sx={{ padding: 1 }} onClick={() => handleProductDetail(item)} style={{ height: matchesMd ? 'auto' : '75%' }}>
                <Grid item xs={12}>
                    <img 
                        src={item.main_photo} 
                        alt="Portfolio" 
                        style={{ width: '100%', height: matchesMd ? 350 : 250, cursor: 'pointer', objectFit: 'cover' }} 
                    />
                </Grid>
                <Grid item xs={12} style={{ fontFamily: 'kanit' }}>
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>{item.name}</span>, 
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>{item.city}</span>
                    <span style={{ marginLeft: 48 }}>
                        <img src={venuestar} style={{ width: 13, height: 13, marginRight: 3 }} />5.0
                    </span>
                    <span style={{ marginLeft: 3 }}>({item.Total_review} review)</span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                    <span><LocationOnIcon style={{ alignItems: 'center' }} fontSize='small' /></span>
                    <span style={{ alignItems: 'center', fontSize: 18 }}>{item.city}</span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                    <span><AccountBalanceIcon style={{ alignItems: 'center', marginRight: 4 }} fontSize="small" /> Banquet Halls, Lawns/Farmhouses, {item.area}</span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                    {item.price_title}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5, display: 'flex' }}>
                    &#x20B9; {item.main_price} / {item.main_price_name}
                </Grid>
            </Paper>
        </Grid>
        ))
    };



   

    return(
        !venuecard?<>
        <div style={{width:'100%',height:'auto',overflowX:'hidden',fontFamily:'kanit',paddingBottom:matchesMd?<></>:6}} >
            
        {matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
   
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>


     {matchesMd?<></> :
<Grid item xs={12}>
     <UpperFilter />
     </Grid>
   
}

   
       
      <Grid item xs={12} style={{fontSize:matchesMd?14:25,fontWeight:'bold',fontFamily:'kanit',color:'#e72e77',padding:15}}>
            Wedding All Venues
            </Grid>

            <Grid item xs={12} style={{fontSize:20,fontWeight:'bold',color:'#e72e77'}}>
                <Divider/>
            </Grid>

            


            <Box sx={{ }}  >
            <Grid container spacing={1}>

        {renderVenueCard()}

        </Grid>
        </Box>
        
        </div>
        
        </>:
    
    
   
   
   
   
   
   
   <div style={{width:'100%',height:'auto',overflowX:'hidden',fontFamily:'kanit',paddingBottom:matchesMd?<></>:6}} >
        
        {matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
   
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>


     
     
     {matchesMd?<></> :
<Grid item xs={12}>
     <UpperFilter item={venuecard} />
     </Grid>
   
}




     <Grid item xs={12} style={{fontSize:20,fontWeight:'bold',color:'#e72e77'}}>
                <Divider/>
            </Grid>
       
      <Grid item xs={12} style={{fontSize:matchesMd?14:25,fontWeight:'bold',fontFamily:'kanit',color:'#e72e77',padding:15}}>
            Wedding All Venues
            </Grid>

            <Grid item xs={12} style={{fontSize:20,fontWeight:'bold',color:'#e72e77'}}>
                <Divider/>
            </Grid>

           
            
            <Box sx={{ }}  >
        <Grid container spacing={1}>
          
        {isShimmer?<><ShimmerSimpleGallery card imageHeight={300}  col={4} gap={30} /></>:
            <>
           
            <Grid 
              item 
              xs={12} sm={4}  // 6 columns on xs, 3 columns on sm and larger
              key={venuecard.photo} 
              
              style={{ display: 'flex', justifyContent: 'center',  width:'90%',height:matchesMd?'auto':600,aspectRatio:2/2,marginTop:30}}
            >
              <Paper elevation={3} sx={{ padding: 1 }} onClick={()=>handleProductDetail(venuecard)} style={{height:matchesMd?'auto':'75%'}}>
                <Grid item xs={12} >
                <img 
                  src={venuecard.main_photo} 
                  alt="Portfolio" 
                  style={{ width: '100%', height: matchesMd?'100%':250, cursor: 'pointer', objectFit: 'cover' }}
                />

</Grid>

<Grid item xs={12} style={{fontFamily:'kanit'}}>
<span style={{fontSize:20,fontWeight:'bold'}}>{venuecard.name}</span>,<span style={{fontSize:20,fontWeight:'bold'}}>{venuecard.city}</span><span style={{marginLeft:48}}><img src={venuestar} style={{width:13,height:13,marginRight:3}} />5.0</span><span style={{marginLeft:3}}>({venuecard.Total_review}  review)</span>
</Grid>

<Grid item xs={12} style={{marginTop:5,display:'flex'}}>
<span ><LocationOnIcon style={{alignItems:'center'}} fontSize='small'/></span><span  style={{alignItems:'center',fontSize:18}}>{venuecard.city}</span>
</Grid>

<Grid item xs={12} style={{marginTop:5,display:'flex'}} >
<span><AccountBalanceIcon style={{alignItems:'center',marginRight:4}} fontSize="small"/> Banquet Halls,Lawns/Farmhouses , {venuecard.area}</span>
</Grid>

<Grid item xs={12} style={{marginTop:5,display:'flex'}}>
    {venuecard.price_title}
</Grid>


<Grid item xs={12} style={{marginTop:5,display:'flex'}}>
&#x20B9; {venuecard.main_price} / {venuecard.main_price_name}
</Grid>




              </Paper>

             

              
</Grid>



</>

}
         

         


        </Grid>
      
       
      </Box>
           

             {/* Backdrop Loader */}
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div style={{width:'20%'}}>
                <Lottie loop={true} animationData={loader}/>
                </div>
            </Backdrop>







        


    </div>)

}

    

