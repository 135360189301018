import { Divider, Grid,Paper, Typography } from "@mui/material"
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import { useState,useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




export default function LocalPriceComponent(props){
  var data=props.item
  console.log(data)

    const theme = useTheme();

    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    const [pricingInfo, setPricingInfo] = useState([]);
    


    useEffect(() => {
      if (data) {
        setPricingInfo(data?.pricing_info || []);
        
      }
   }, [data]);


   

    return(<Paper elevation={1} style={{  
    
          width: matchesMd ? '100vw' : 500,
          height: 'auto',
          boxSizing: 'border-box',
         
          
          
          }}>




        <Grid container spacing={3}>
            {/* <Grid item xs={6} style={{ 
          fontFamily: 'kanit', 
          color: '#000', 
          display: 'flex', 
          justifyContent: 'flex-start', 
          alignItems: 'center',
          fontWeight:'bold'
           }}>
             Staring Price
            </Grid>

            <Grid item xs={6}  style={{ 
          fontFamily: 'kanit', 
          color: 'black', 
         
          display: 'flex', 
          justifyContent: 'flex-end', // Positions the content at the end of the row
          alignItems: 'flex-start', // Aligns content to the start of the flex direction (left side)
          color:'#e72e77',
          fontWeight:'bold'
        
        }}>
         PricingInfo
          </Grid> */}

            {/* <Grid item xs={12}>
                <Divider/>
            </Grid> */}

<Grid item xs={12}>
<Accordion>
        <AccordionSummary
          expandIcon= { <ExpandMoreIcon />}
         
          aria-controls="panel1-content"
          id="panel1-header"
        >
         <Typography style={{ 
          fontFamily: 'kanit', 
          color: '#000', 
       
         
          alignItems: 'center',
          fontWeight:'bold',
          fontSize:14
           }}> Start Price</Typography>
           
           <Typography style={{ 
            fontFamily: 'kanit', 
            color: 'black', 
            marginLeft:'68%',
            fontSize:15,
           
            display: 'flex', 
       alignItems:'center', // Positions the content at the end of the row
           
            color:'#e72e77',
            fontWeight:'bold'
          
          }}>pricingInfo</Typography>
        </AccordionSummary>
        <AccordionDetails style={{display:'flex',background:'#dfe6e9'}}>
            <Grid item xs={12}>
         <span  style={{fontSize:13,display:'flex',fontWeight:'bold',color:'#636e72'}}>Starting Price Of Room</span>  <span  style={{display:'flex',alignItems:'center',fontSize:14,marginTop:5,color:'#636e72'}}> &#x20B9;2,500 per room</span>
         </Grid>

         <Grid item xs={12}>
         <span style={{display:'flex',fontSize:15,fontWeight:'bold',fontSize:13,marginLeft:5,color:'#636e72'}} > Starting Price Of Decor </span>  <span  style={{display:'flex',fontSize:14,marginTop:5,marginLeft:5,color:'#636e72'}}>&#x20B9;55,000</span>
         </Grid>
        </AccordionDetails>
      </Accordion>

      </Grid>


            

            {pricingInfo?.map((item) => (
                

          <>

             <Grid item xs={12} style={{display:'flex',alignItems:'center'}}>
                
           <span style={{color:'#e72e77',fontSize:15,marginLeft:20}}>  &#x20B9;{item.price} </span> <span style={{display:'flex',marginLeft:'auto',marginRight:20}}> {item.price_name}</span>
           
          
            </Grid>

            <Grid item xs={12}>
                <Divider/>
             </Grid>
              
              
                </>


        ))}




        </Grid>

    </Paper>)

}