import { Grid, useMediaQuery } from "@mui/material";
import FilterComponent from "../Component/userinterface/FilterComponent";
import SearchBarComponent from "../Component/userinterface/SearchBarComponent";
import Header from "../Component/userinterface/Header";
import UpperHeader from "../Component/userinterface/UpperHeader";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { getData } from "../Services/FetchNodeServices";
import { useState, useEffect } from "react";
import CategoryCard from "../Component/CategoryCard";
import Footer from "../Component/userinterface/Footer";

export default function ShopScreen() {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
    const [categoryList, setCategoryList] = useState([]);

    var location = useLocation();
   
    var categoryId = localStorage.getItem("category");
   
    
    var subcategoryid = location?.state?.item?.id;

    // Check if subcategoryid exists in the state, otherwise show all categories
   

    const fetchPortfolio = async () => {
        let result;
        if (subcategoryid) {
            // If a subcategory is selected, filter by it
            result = await getData(`api-listing?subcategory_id=${subcategoryid}`);
        } else {
            // If no subcategory is selected, show all items of the category
            result = await getData(`api-listing?category_id=${categoryId}`);
        }

        if (result.status) {
            setCategoryList(result.data);
            console.log('setCategoryList',result.data)
        }
    };

    useEffect(() => {
        if (categoryId) {
            fetchPortfolio();
        }
    }, [categoryId, subcategoryid]);

    return (
        <div>
            {!matchesMd && <Grid item xs={12}><UpperHeader /></Grid>}

            <Grid item xs={12}><Header /></Grid>

            <Grid item md={12} style={{ display: matchesMd ? '' : 'flex' }}>
                <Grid item xs={12} md={4} style={{marginTop:matchesMd?25:<></>}}>
                    <FilterComponent  />
                </Grid>

                <Grid item xs={12} md={8} style={{ display: 'flex', width: '100vw', overflowX: 'hidden' }}>
                    <CategoryCard item={categoryList} subcategoryid={subcategoryid}  />
                </Grid>


            </Grid>

            <Grid item xs={12} style={{marginTop:'20px'}}>
                <Footer/>
            </Grid>
        </div>
    );
}
