import { Grid,Divider, DialogTitle,TextField,Typography,Button} from "@mui/material"
import LocalPriceComponent from "../Component/userinterface/LocalPriceComponent"
import PaymentDetails from "../Component/userinterface/PaymentDetails"
import Header from "../Component/userinterface/Header"
import UpperHeader from "../Component/userinterface/UpperHeader"
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
 import CartImage from "../Component/userinterface/CartImage"
import CartImageDetails from "../Component/userinterface/CartImageDetails"
import CartMiddleHeader from "../Component/userinterface/CartMiddleHeader"
import AreasAvailableComponent from "../Component/userinterface/AreasAvailableComponent"
import AboutCartPage from "../Component/userinterface/AboutCartPage"
import Footer from "../../src/Component/userinterface/Footer"
import ReviewComponent from "../Component/userinterface/ReviewComponent"
import CartTabs from "../Component/userinterface/CartTabs"
import BrowseImage from "../Component/userinterface/BrowseImage"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { useEffect} from "react";
import { getData,postData } from "../../src/Services/FetchNodeServices";
import mail from "../assets/mail.jpg"
import phone from "../assets/phone.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import register from "../assets/register.png"
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";



 export default function Cart(){

    const theme = useTheme();
    var location=useLocation()
    var item=localStorage.getItem("cartItem");
    console.log("ttttttttttttt",item)
    const { id } = useParams();
    console.log("Item ID:", id);
   console.log('vvvvvvv',location?.state?.data)
   
    var userLogin=useSelector(state=>state.user)
    var navigate=useNavigate()
   
    


   
    const [portfolioData, setPortFolioData] = useState([]);
    const [loading,setLoading]=useState(false)
    const [isGreenHovered, setIsGreenHovered] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
   
  
    

   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

//    const [myStyle,setMyStyle]=useState({
//     background:'black'
//    })
const [darkMode, setDarkMode] = useState(false);

const createSession=async()=>{
    const body={email: "9907026112",password:"12345678"}
    const result= await postData("login",body)
    if(result.token_status)
        {
            localStorage.setItem("token",result.data.user.jwt_token)

    }

}


// const fetchAllData = async () => {
//     setLoading(true);
//     const result = await getData(`api-listing/${item.id}`)
//     if (result.status) {
//         setPortFolioData(result.data)
       
     
//     }
//     setLoading(false)
// }

// useEffect(() => {
    
//   if (!localStorage.getItem("token")) {
//       createSession().then(() => {
//           fetchAllData()
//       })
//   }
//   fetchAllData()
// }, [])

const fetchAllData = async () => {
    setLoading(true);
    try {
        const result = await getData(`api-listing/${id}`); // Use 'id' from URL
        if (result.status) {
            setPortFolioData(result.data);
           
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    setLoading(false);
};

useEffect(() => {
    // Check if user is logged in or create session
    if (!localStorage.getItem("token")) {
        createSession().then(() => {
            fetchAllData();
        });
    } else {
        fetchAllData();
    }
}, [id]); // Make sure to re-fetch when 'id' changes

if (loading) {
    return <div></div>;
}

if (!portfolioData) {
    return <div></div>;
}




 

 

const handleClickOpen = (id, item) => {
    navigate("/registration", { state: { open: true, from: `/cart/${id}`, id, item } });
};


    const handleClose = () => setOpen(false);

    const handleClickMessage = () => setExpand(true);
    const handleCloseMessage = () => setExpand(false);
      

    const handleSubmit = async () => {
        try {
            const body = { phone: number, name: name, email: email, message: message,listing_id:id};
            const result = await postData('api-enquiry', body);
            console.log('API Result:', result); 
    
            if (result && result.status) {
                // Store the user details in local storage
                // localStorage.setItem("use", JSON.stringify(body));
               
    
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Message Sent Successfully....",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
    
                // Dispatching action to add user to Redux store
                // dispatch({ type: 'ADD_USER', payload: body });
    
                // Navigate to profile after successful registration
                // navigate("/profile");
            } else {
                console.error("Message failed");
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Message failed. Please try again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
            }
        } catch (error) {
            console.error("An error occurred during registration:", error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "An error occurred. Please try again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
        }
    };




    return(<div  style={{overflowX:'hidden',background: "white"}}>

        

{matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>


     {/* <Button style={{display:'flex',marginLeft:'auto'}} size="small" onClick={toggleStyle} variant="contained" item xs={12}>
     {!darkMode ? "Enable Light Mode" : "Enable Dark Mode"}
     </Button> */}

{/* sticky button start */}
        <Grid container spacing={0}>

            {userDetails?  <Grid item xs={12} style={{  position: 'fixed', top: 0 }}>
        
        <div   onClick={handleClickMessage}
            style={{
                position: 'sticky',
                top: 0,
                width: '50px', // Equal width and height to create a circle
                height: '50px',
                color: '#fff',
                borderRadius: '50%', // Make the div circular
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                background: isPinkHovered
                    ? 'linear-gradient(to bottom, #a0d6a3, #7ac140)'
                    : 'linear-gradient(to bottom, #c4e4b4, #a0d6a3)',
                cursor: 'pointer', // Optional: add a pointer cursor to indicate it's clickable
                marginTop:80,
               
            }}
            onMouseEnter={() => setIsPinkHovered(true)}
            onMouseLeave={() => setIsPinkHovered(false)}
        >
            <img
                src={mail}
                style={{
                    width: '40%',
                    height: '40%',
                    alignItems: 'center',
                    borderRadius: '50%',
                }}
            />
        </div>
   

    
       
    
</Grid>:

        <Grid item xs={12} style={{  position: 'fixed', top: 0 }}>
        
            <div   onClick={handleClickMessage}
                style={{
                    position: 'sticky',
                    top: 0,
                    width: '50px', // Equal width and height to create a circle
                    height: '50px',
                    color: '#fff',
                    borderRadius: '50%', // Make the div circular
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    background: isPinkHovered
                        ? 'linear-gradient(to bottom, #a0d6a3, #7ac140)'
                        : 'linear-gradient(to bottom, #c4e4b4, #a0d6a3)',
                    cursor: 'pointer', // Optional: add a pointer cursor to indicate it's clickable
                    marginTop:80,
                   
                }}
                onMouseEnter={() => setIsPinkHovered(true)}
                onMouseLeave={() => setIsPinkHovered(false)}
            >
                <img
                    src={mail}
                    style={{
                        width: '40%',
                        height: '40%',
                        alignItems: 'center',
                        borderRadius: '50%',
                    }}
                />
            </div>
       

        
            <div
               onClick={() => handleClickOpen(id,item)}
                style={{
                    width: '50px',
                    height: '50px',
                    color: '#fff',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    
                    cursor: 'pointer',
                    marginTop:10
                }}
                onMouseEnter={() => setIsGreenHovered(true)}
                onMouseLeave={() => setIsGreenHovered(false)}
            >
                <img
                    src={register}
                    style={{
                        width: '55px',
                        height: '55px',
                        alignItems: 'center',
                        borderRadius: '50%',
                    }}
                />
            </div>
        
    </Grid>
    
    
    }

    




    <Grid item xs={12} style={{display:'flex'}}>
                
                <CartImage />
                
                
                </Grid>

           
           
           
           
           
           
           
            <Grid item xs={12} md={7}  >
        
               
                {matchesMd?

                <Grid item xs={12} style={{marginTop:matchesMd?<></>:50}}>
                    <Divider/>
                </Grid>
                :<></>}

                <Grid item xs={12} style={{ marginTop:matchesMd?<></>:20,display:'flex',justifyContent:'center'}}>
                <CartImageDetails />
                </Grid>

                {/* {matchesMd?

            <Grid item xs={12} style={{marginTop:matchesMd?30:50}}>
           <Divider/>
           </Grid>
           :<></>} */}


           
               
              {matchesMd?

          <Grid item xs={12} style={{marginTop:matchesMd?15:<></>,display:'flex',justifyContent:'center'}}>
        <LocalPriceComponent item={portfolioData}/>
           </Grid>
           :

                <Grid item xs={12} style={{marginTop:20,display:'flex',justifyContent:'center'}}>
                    <AreasAvailableComponent item={portfolioData} />
                </Grid>
               }

               {/* {matchesMd?
               <Grid item xs={12}>
                <PricingInfo/>
               </Grid>
               :
               <></>
                } */}


              {matchesMd?
               <Grid item xs={12} style={{marginTop:matchesMd?15:<></>,display:'flex',justifyContent:'center'}}>
                 <AreasAvailableComponent item={portfolioData} />
                 </Grid>
               :
               <></>
                }



                
               

                

               

                {/* <Grid item xs={12} style={{marginTop:matchesMd?<></>:50,display:'flex',justifyContent:'center'}}>
                    <Portfolio/>
                </Grid> */}


            </Grid>



            <Grid item xs={12}  md={5}>

                {matchesMd?<></>:
                <Grid item xs={12} style={{marginTop:20,display:'flex'}}>
             <LocalPriceComponent item={portfolioData}/>
             </Grid>
                  }


         
            
             
             {/* <Grid item xs={12} style={{marginTop:30,display:'flex',marginLeft:matchesMd?60:50,}}>
             <BrowseImage/>
             </Grid> */}



            </Grid>

            <Grid item xs={12} style={{marginTop:matchesMd?15:20,display:'flex'}}>
                    <CartTabs />
                </Grid>
   

 

            <Grid item xs={12} style={{marginTop:matchesMd?<></>:20}}>
                <AboutCartPage/>
            </Grid> 
             

            

            <Grid item xs={12} style={{marginTop:matchesMd?<></>:20}}>
                <ReviewComponent/>
            </Grid>

            <Grid item xs={12}  style={{marginTop:matchesMd?40:70}}>
                <Divider/>

            </Grid>

           

            <Grid item xs={12} style={{marginTop:20}}>
                <Footer/>
            </Grid>

            






        </Grid>



       {/* Dialog for contact information */}
       <Dialog maxWidth="lg" open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        Verify your mobile to contact the vendor
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', width: matchesMd ? '85%' : '65%', height: '28%', border: 'solid 1px' }}>
                            <PersonIcon style={{ padding: 10 }} />
                            <input style={{ height: 50, width: '100%', background: 'transparent', border: 'none', outline: 'none', color: '#797979', fontSize: 12, marginLeft: matchesMd ? '' : 10 }} type="text" placeholder="Full Name" />
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', width: matchesMd ? '85%' : '65%', height: '28%', border: 'solid 1px' }}>
                            <LocalPhoneIcon style={{ padding: 10 }} />
                            <input style={{ height: 50, width: '100%', background: 'transparent', border: 'none', outline: 'none', color: '#797979', fontSize: 12, marginLeft: matchesMd ? '' : 10 }} type="text" placeholder="Enter Number" />
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', height: '30%', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            style={{
                                width: matchesMd ? 80 : 110,
                                height: 40,
                                background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)',
                                fontSize: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                marginTop: 30,
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={() => setIsGreenHovered(true)}
                            onMouseLeave={() => setIsGreenHovered(false)}
                        >
                            Verify Number
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Expandable message dialog */}
            {/* <Dialog open={expand} onClose={handleCloseMessage} fullWidth>
            <DialogTitle>Send a Message</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ color: '#485460', fontFamily: 'kanit', fontWeight: 'bold', fontSize: 16 }}>
                    <Grid item xs={12}>
                        <div style={{ marginLeft: '3%' }}>Listing Id:{}</div>
                    </Grid>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="full-name"
                                placeholder="Full Name"
                                variant="standard"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ margin: '3% 0' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="mobile-number"
                                placeholder="Enter Mobile Number"
                                variant="standard"
                                fullWidth
                                onChange={(e) => setNumber(e.target.value)}
                                style={{ margin: '3% 0' }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email-address"
                                placeholder="Email Address"
                                variant="standard"
                                fullWidth
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ margin: '3% 0' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="text"
                                id="function-date"
                                placeholder="Enter Message"
                                fullWidth
                                variant="standard"
                                onChange={(e) => setMessage(e.target.value)}
                                style={{ margin: '3% 0' }}
                            />
                        </Grid>
                    </Grid>

                    

                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseMessage} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Send Message
                </Button>
            </DialogActions>
        </Dialog> */}

<Dialog open={expand} onClose={handleCloseMessage} fullWidth>
    <div
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url("https://img.pikbest.com/wp/202347/pink-love-heart-backgrounds-3d-illustration-of-a-soft-fluffy-on-light-background-sending-message-in-realistic-colors_9758709.jpg!sw800")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
            opacity: 0.6, // Adjust to your preference
        }}
    />
    <div style={{ position: 'relative', zIndex: 2 }}>
        <DialogTitle style={{color:'#fff',justifyContent:'center',fontWeight:'bold'}}>Send a Message</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} style={{ color: '#485460', fontFamily: 'kanit', fontWeight: 'bold', fontSize: 16 }}>
                <Grid item xs={12}>
                    <div style={{ color:'#fff',fontSize:20, }}>Listing Id: {id}</div>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="full-name"
                            placeholder="Full Name"
                            variant="standard"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ margin: '3% 0' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="mobile-number"
                            placeholder="Enter Mobile Number"
                            variant="standard"
                            fullWidth
                            onChange={(e) => setNumber(e.target.value)}
                            style={{ margin: '3% 0' }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="email-address"
                            placeholder="Email Address"
                            variant="standard"
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ margin: '3% 0' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="text"
                            id="function-date"
                            placeholder="Enter Message"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ margin: '3% 0' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseMessage} color="secondary" variant="contained" >
                Cancel
            </Button>
            <Button onClick={handleSubmit}  color="secondary" variant="contained" >
                Send Message
            </Button>
        </DialogActions>
    </div>
</Dialog>




    </div>)
}