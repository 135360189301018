import { Grid } from "@mui/material"
import { getData, postData, serverURL } from "../../Services/FetchNodeServices";
import { useEffect, useState } from "react";
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import img from "../../assets/articleimg.avif"




export default function ArticleBreadcrump(props){
    

    
    const theme = useTheme();
   

   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

   





  


    


   


    return (
        <div style={{ width: matchesMd ? '100%' : '100%', height: matchesMd ? '' : '100%', position: 'relative' }}>
         
              <img src={img} alt="Article" style={{ width: '100%', height: matchesMd ? 300 : 370, borderRadius: 5 }} />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent overlay
                  borderRadius: 5,
                }}
              >
                <h1 style={{ fontSize: matchesMd ? '3.5rem' : '6rem', fontWeight: 'bold', textAlign: 'center',fontFamily:'pacifico' }}>
                  Articles
                </h1>
              </div>
           
         
        </div>
      );
      
}