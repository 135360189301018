import { Grid, Button, DialogTitle, Dialog, DialogActions, DialogContent } from "@mui/material";
import RegisterNow from "../assets/RegisterNow.webp";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import { postData } from "../Services/FetchNodeServices";
import Swal from "sweetalert2";

export default function Registration() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [open, setOpen] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { open: isOpen, from = null, id = null } = location.state || {};
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        }
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            const body = { phone: number, name, email, password };
            console.log("Request body:", body);
            
            const result = await postData("api-user", body);
            console.log("API response:", result);

            if (result && result.status) {
                localStorage.setItem("userDetails", JSON.stringify(body));
                
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "You are registered now....",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });

                if (from) {
                    navigate(from, { state: { id, from } });
                } else {
                    navigate("/profile");
                }
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Registration failed. Please try again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
            }
        } catch (error) {
            console.error("An error occurred during registration:", error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "An error occurred. Please try again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
        }
    };

    return (
        <Dialog maxWidth={"lg"} open={open} onClose={handleClose}>
            <DialogTitle>
                <Grid container justifyContent="center" alignItems="center">
                    <img src={RegisterNow} style={{ width: '13%', height: '10%' }} alt="Register Now"/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {[{
                    icon: <PersonIcon style={{ padding: 10 }} />,
                    value: name,
                    onChange: (e) => setName(e.target.value),
                    placeholder: "Enter Name"
                }, {
                    icon: <LocalPhoneIcon style={{ padding: 10 }} />,
                    value: number,
                    onChange: (e) => setNumber(e.target.value),
                    placeholder: "Enter Number"
                }, {
                    icon: <EmailIcon style={{ padding: 10 }} />,
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                    placeholder: "Enter E-mail Id"
                }, {
                    icon: <VpnKeyIcon style={{ padding: 10 }} />,
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                    placeholder: "Enter Password"
                }].map((field, index) => (
                    <Grid item xs={12} key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', width: matchesMd ? '85%' : '65%', height: '28%', border: 'solid 1px' }}>
                            {field.icon}
                            <input 
                                value={field.value} 
                                onChange={field.onChange} 
                                style={{ height: 50, width: '100%', background: 'transparent', border: 'none', outline: 'none', color: '#797979', fontSize: 12, marginLeft: matchesMd ? '' : 10 }} 
                                type="text" 
                                placeholder={field.placeholder} 
                            />
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                    <div 
                        onClick={handleSubmit} 
                        style={{
                            width: matchesMd ? 80 : 90, 
                            height: 35, 
                            background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d)' : '#fff',
                            display: 'flex', 
                            color: isPinkHovered ? 'white' : '#e72e77', 
                            fontSize: 12, 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            fontWeight: 'bold', 
                            borderRadius: 30, 
                            border: 'solid 1px', 
                            cursor: 'pointer'
                        }} 
                        onMouseEnter={() => setIsPinkHovered(true)} 
                        onMouseLeave={() => setIsPinkHovered(false)}
                    >
                        Submit
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
