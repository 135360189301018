import { Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LoginLogOutDropDown(props) {
    const navigate = useNavigate();
    
    const handleLogOut = () => {
        
        // Optionally navigate to a different page after logout
        navigate('/login'); // Replace '/login' with the path to your login page
    }
    
    return (
        <Paper
            elevation={2}
            style={{
                display: props?.isOpen ? 'flex' : 'none',
                position: 'absolute',
                top: 50,
                zIndex: 3,
                marginLeft: '1120px',
                cursor: 'pointer',
                paddingBottom: 30,
                paddingTop: 20,
                flexDirection: 'column',
                width: '150px', 
                fontFamily: 'kanit'
            }}
        >
            <Grid
                container
                direction="column"
                style={{ gap: '10px', padding: '0 20px' }} // Added some padding and gap between items
            >
                <Grid item>
                    User Profile
                </Grid>
                <Grid item>
                    <button onClick={handleLogOut} style={{ cursor: 'pointer', background: 'none', border: 'none', color: 'blue', textDecoration: 'underline' }}>
                        Log Out
                    </button>
                </Grid>
            </Grid>
        </Paper>
    );
}
