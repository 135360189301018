import React, { useState, useEffect } from 'react';
import { Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getData, postData } from '../../Services/FetchNodeServices';

export default function ShowList(props) {
  const [portfolioData, setPortFolioData] = useState([]);
  const [picture, setPicture] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null); // State to track hovered item
  const navigate = useNavigate();

  const fetchAllData = async () => {
    const result = await getData('api-listing?venue');
    if (result.status) {
      setPortFolioData(result.data);
      console.log('ggggggggggggggg',result.data);
    }
  };

  const fetchPicture = async () => {
    const result = await getData('api-category?is_feature=1');
    if (result.status) {
      setPicture(result.data);
      console.log(result.data);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      createSession().then(() => {
        fetchAllData();
        fetchPicture();
      });
    } else {
      fetchAllData();
      fetchPicture();
    }
  }, []);

  const createSession = async () => {
    const body = { email: "9907026112", password: "12345678" };
    const result = await postData("login", body);
    if (result.token_status) {
      localStorage.setItem("token", result.data.user.jwt_token);
    }
  };

  const handleVenue = (item) => {
    localStorage.setItem("cartItem", JSON.stringify(item));
    navigate(`/cart/${item.id}`);
};




  // Inline styles
  const defaultStyle = {
    transition: 'transform 0.3s ease, background-color 0.3s ease',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#fff', // Default background color
  };

  const hoverStyle = {
    ...defaultStyle,
    transform: 'scale(1.05)',
    backgroundColor: '#f8d9e0', // Light pink color
  };

  return (
    <Paper elevation={2} style={{ display: props?.isOpen ? 'flex' : 'none', position: 'absolute', top: 50, zIndex: 3, marginLeft: 100, cursor: 'pointer', paddingBottom: 30, paddingTop: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ fontFamily: 'kanit', color: '#e72e77', fontSize: 18, fontWeight: 'bolder' }}> Venues...</div>
          {portfolioData?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleVenue(item)}
              onMouseEnter={() => setHoveredItem(item)}
              onMouseLeave={() => setHoveredItem(null)}
              style={hoveredItem === item ? hoverStyle : defaultStyle}
            >
              <div style={{ fontFamily: 'kanit', fontSize: 13, fontWeight: 'bold' }}>{item.name}</div>
            </div>
          ))}
        </Grid>

        {/* <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ fontFamily: 'kanit', color: '#e72e77', fontSize: 18, fontWeight: 'bolder' }}>By City</div>
          {portfolioData?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleVenue(item)}
              onMouseEnter={() => setHoveredItem(item)}
              onMouseLeave={() => setHoveredItem(null)}
              style={hoveredItem === item ? hoverStyle : defaultStyle}
            >
              <div style={{ fontFamily: 'kanit', fontSize: 15, fontWeight: 'bold' }}>{item.city}</div>
            </div>
          ))}
        </Grid> */}

        <Grid item xs={6}>
          <Grid container spacing={2}>
            {picture.slice(8, 17).map((item, index) => (
              <Grid item xs={4} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={item.photo}
                  alt={`Portfolio ${index}`}
                  style={{ width: 95, height: 95, objectFit: 'cover', cursor: 'pointer', aspectRatio: 2 / 2, borderRadius: 60, padding: 5 }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
