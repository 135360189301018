import { Divider, Grid,Paper } from "@mui/material"
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";

export default function AboutCartPage(){

    const theme = useTheme();

    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    return(<Paper style={{fontFamily:'kanit',marginLeft:matchesMd?<></>:20,marginRight:matchesMd?<></>:20,padding:matchesMd?4:10,width:matchesMd?'100%':'94%',height:'100%'}}>
        <Grid container spacing={2}>
            <Grid item xs={12} style={{alignItems:'center',marginTop:10,display:'flex',fontSize:matchesMd?'0.8rem':20,fontWeight:'bold'}}>
                About Venus Banquet,Gwalior-Wedding Venues,Gwalior
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} style={{fontSize:matchesMd?'0.6rem':14,color:'#e72e77'}}>
            There are various ways to dine outside the home: restaurants, cafeterias, fast-food chains, concession stands at ballparks, gas station, and food stops etc. In every prospect, we have probably also encountered a conference, meeting, or event, such as a wedding, with food service available to attendees. Most often, these types of events are classified as banquet service.

A banquet service is usually nothing more than a more elaborate meal due to the scope of the event or size of the crowd present. Banquet service can be as differ as the style of the event and, usually, the type of service is somewhat decided by the extent of the event, menu, and crowd. Here are a few types of the banquet service arrangements that are common to the banquet providers, such as caterers, hotels, and banquet halls.
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item md={3} xs={12} style={{lineHeight:2}}>
               <div style={{fontSize:14,fontWeight:'bold'}}>Been On WedMeGood Since</div>
               <div style={{fontSize:12}} >5 Years 5 Months</div>
               <div style={{fontSize:12}}>Start Of Venue</div>
               <div style={{fontSize:12}}>2017</div>
            </Grid>

            <Grid item md={3} xs={12} style={{lineHeight:2}}>
               <div style={{fontSize:14,fontWeight:'bold'}}>Catering Policy</div>
               <div style={{fontSize:12}}>Outside Catering Only</div>
               <div style={{fontSize:12}}>In House Alcohol is not available</div>
               <div style={{fontSize:12}}>Outside Alcohol is Permitted</div>
            </Grid>

            <Grid item md={3} xs={12}  style={{lineHeight:2}}>
               <div style={{fontSize:14,fontWeight:'bold'}}>Decor Policy</div>
               <div style={{fontSize:12}}>Inhouse Decor</div>
               <div style={{fontSize:12}}>DJ Policy</div>
               <div style={{fontSize:12}}>DJ Permitted</div>
            </Grid>

            <Grid item md={3} xs={12} >
               <div style={{fontSize:14,fontWeight:'bold'}}>Space</div>
               <div style={{fontSize:12}}>Indoor</div>
              
            </Grid>

          

         



        </Grid>

    </Paper>)
}