import { Divider, Grid,useMediaQuery,Box,Paper } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Header from "../Component/userinterface/Header";
import UpperHeader from "../Component/userinterface/UpperHeader";
import { useState,useEffect } from "react";
import { serverURL,postData,getData } from "../Services/FetchNodeServices";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import loader from '../../src/loader.json'
import Lottie from "lottie-react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Footer from "../Component/userinterface/Footer";


export default function PortfolioViewMore(){

    const [portFolioImage, setPortFolioImage] = useState([])
    const [loading, setLoading] = useState(false);
    const[isShimmer,setIsShimmer]=useState(true)

    const theme = useTheme();

   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));



   const fetchPortfolio = async () => {
    setLoading(true);
    const result = await getData('api-category?is_feature=1')
    if (result.status) {
      setIsShimmer(false)
        setPortFolioImage(result.data)
       // console.log(result.data)
    }
    setLoading(false);
}



const createSession = async () => {
    setLoading(true)
    const body = { email: "9907026112", password: "12345678" }
    const result = await postData("login", body)
    if (result.token_status) {
        localStorage.setItem("token", result.data.user.jwt_token)
    }
    setLoading(false);
}

useEffect(() => {
    if (!localStorage.getItem("token")) {
        createSession().then(() => {
          setTimeout(()=>(
            fetchPortfolio()
          ),5000)
        })
    }
    fetchPortfolio()
}, [])

    return(<div style={{width:'100%',height:'100%',margin:0,padding:0,overflowX:'hidden',fontFamily:'kanit'}} >
        {matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
}
        
       
      

       <Grid item xs={12}>
     <Header/>
     </Grid>

            <Grid item xs={12} style={{fontSize:25,fontWeight:'bold',fontFamily:'kanit',color:'#e72e77',padding:25}}>
                Portfolio
            </Grid>

            <Grid item xs={12} style={{fontSize:20,fontWeight:'bold',color:'#e72e77'}}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>

            <Box sx={{ padding: 2 }}  >
        <Grid container spacing={2}>
          
          {isShimmer?<><ShimmerSimpleGallery card imageHeight={300}  col={4} gap={30} /></>:portFolioImage.map((item) => (
            <Grid 
              item 
              xs={6} sm={2}  // 6 columns on xs, 3 columns on sm and larger
              key={item.photo} 
              
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'30%',height:'30%' }}
            >
              <Paper elevation={3} sx={{ padding: 1 }}>
                <img 
                  src={item.photo} 
                  alt="Portfolio" 
                  style={{ width: '100%', height: 'auto', cursor: 'pointer', objectFit: 'cover' ,aspectRatio:2/2}}
                />
              </Paper>
              
</Grid>




          ))}
         

         


        </Grid>
      
       
      </Box>
            </Grid>

             {/* Backdrop Loader */}
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div style={{width:'20%'}}>
                <Lottie loop={true} animationData={loader}/>
                </div>
            </Backdrop>

<Grid item xs={12}>
<Footer/>
</Grid>





        


    </div>)







}