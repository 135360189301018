import { useState,useContext,useEffect } from "react";
import { Avatar,AppBar,Box,Toolbar,Typography,Grid,Paper,useMediaQuery,Button } from "@mui/material";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Routes,Route,Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import user from "../assets/1.webp"
import { useStyles } from "./ProfileCss";
import WishList from "../Component/userinterface/WishList";
import PreviousOrders from "../Component/userinterface/PreviousOrders";
import UpperHeader from "../Component/userinterface/UpperHeader";
import Header from "../Component/userinterface/Header";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../App"
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { getData,postData } from "../../src/Services/FetchNodeServices";
import { useSelector } from "react-redux";
import Footer from "../Component/userinterface/Footer";









//import Summary from "./Summary";
//import Chart from "../../components/DashboardComponent/Chart";



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  
  
  
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




  export default function Profile(props){
    const classes=useStyles();
    const navigate=useNavigate();
    const theme = useTheme();
    const [value,setValue]=useState('')
    const { setCurrentUser } = useContext(AuthContext);
    const[loginData,setLoginData]=useState([])
     var adminData=JSON.parse(localStorage.getItem('user'))
     const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
     


    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));



   

//   const fetchAllLoginData = async () => {
//     const Login = { email: "9907026112", password: "12345678" }
//     const result = await postData('login',Login)
//     if (result.status) {
//         alert(result.data)
       
//     }
// }

// useEffect(() => {
//   if (!localStorage.getItem("token")) {
//       createSession().then(() => {
//         setTimeout(()=>(
//           fetchAllLoginData()
//         ),5000)
//       })
//   }
//   fetchAllLoginData()
// }, [])

  

//   const createSession = async () => {
//       const body = { email: "9907026112", password: "12345678" }
//       const result = await postData("login", body)
//       if (result.token_status) {
//           localStorage.setItem("token", result.data.user.jwt_token)
//       }
//   }

  



    // const handleLogout = () => {
    //     // Remove email from local storage
    //     localStorage.removeItem("user");
    //     setValue('');
    //     setCurrentUser(null)
    
    //     // Clear Google session
    //     // window.location.href = 'https://accounts.google.com/Logout';
    
    //     // Navigate to the home page or login page
    //     navigate('/login');
    // };

    // useEffect(()=>{
    //     setValue(localStorage.getItem('user'))
    // })

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //   }



    const MobileListTabs=()=>{

        <Box  style={{width: matchesMd?'100%': 700,height:matchesMd?<></>:40,marginLeft:matchesMd?<></>:10,paddingLeft:matchesMd?<></>:10,fontFamily:'kanit'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value}  aria-label="basic tabs example">
            <Tab label="Portfolio" {...a11yProps(0)} />
            <Tab label="Videos" {...a11yProps(1)} />
            <Tab label="Albums" {...a11yProps(2)} />
          </Tabs>
        </Box>
  
        <CustomTabPanel value={value} index={0}>
          <WishList/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <PreviousOrders/>
        </CustomTabPanel>
       
      </Box>
    }

    

    return(
       
        <Box sx={{flexGrow:1}}>
        {/* <AppBar position="sticky">
        <Toolbar variant="dense">
        <Typography variant="h6" color="inherit" component="div">
            Wed Me Good
        </Typography>
        </Toolbar>
       </AppBar> */}

{matchesMd?<></> :
   <Grid item xs={12}>
      <UpperHeader/>
       </Grid>
  }

<Grid item xs={12}>
       <Header/>
      </Grid>

      

       <Grid container spaces={3} style={{paddingInlineStart:matchesMd?0:5}}>
       
        
        <Grid item md={2.2} xs={12}>
       
            <Paper  style={{height:matchesMd?'auto':'100vh'}}>
                <div className={classes.leftBarStyle}>
                    <img src={user}  style={{width:70,height:70,borderRadius:35}}/>
                    <div className={classes.nameStyle}>{userDetails.name}</div>
            <div className={classes.emailStyle}>{userDetails.email}</div>
            
                    {matchesMd?<div><Button   variant="text" style={{fontWeight:'bold',fontSize:12}}>LOG OUT</Button></div>:<></>}
                </div>

                <div className={classes.menuStyle}>
                    <List>
                        <Divider/>
                        
                       
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>navigate('/profile/wishlist')}>
                                <ListItemIcon>
                                    <DraftsIcon/>
                               </ListItemIcon>
                               <ListItemText primary={<span className={classes.menuItemStyle}>WishList</span>}/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>navigate('/profile/previousorders')}>
                                <ListItemIcon>
                                    <DraftsIcon/>
                               </ListItemIcon>
                               <ListItemText primary={<span className={classes.menuItemStyle}>Previous Orders</span>}/>
                            </ListItemButton>
                        </ListItem>

                      
                        <Divider style={{marginTop:20}} />
                        {matchesMd ? <div></div> : 
   
        <ListItem disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    <DraftsIcon style={{ marginTop: 10, display: 'flex' }} />
                </ListItemIcon>
                <ListItemText 
                    primary={<span onClick={()=>navigate('/login')} style={{ marginTop: 10, display: 'flex' }} className={classes.menuItemStyle}>Log Out</span>} 
                />
            </ListItemButton>
        </ListItem>
  
}
                    </List>

                </div>
        
            </Paper>

        
        </Grid>


        <Grid item md={9.8} xs={12} style={{padding:20}}>
            
                <Routes>
                    {/* <Route path="/" element={<Navigate to="/admindashboard/summary" replace={true}/>}/> */}
                    <Route element={<WishList/>} path='/wishlist'/>
                    <Route element={<PreviousOrders/>} path='/previousorders/'/>
                    

                </Routes>

        
            
        </Grid>

        <Grid item xs={12}>
          <Footer/>
        </Grid>
      
      
      
      
     

        
        
        
        
        
        
        
      </Grid>
        </Box>

 )
}