import { Grid,Paper,Divider } from "@mui/material"
import { getData,postData,serverURL } from "../../Services/FetchNodeServices"
import { useState,useEffect } from "react"
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ShimmerSimpleGallery } from "react-shimmer-effects";




export default function Portfolio(){
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate=useNavigate()
  const[isShimmer,setIsShimmer]=useState(true)
  const matchesMd=useMediaQuery(theme.breakpoints.down("md"));
    const [portFolioImage, setPortFolioImage] = useState([])
    // const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    const [portfolioData, setPortFolioData] = useState([]);


    const handleClickOpen = (index) => {
      // setSelectedImage(imageUrl)
      setSelectedImageIndex(index);
      setOpen(true);
    };
  

   

    const handleClose = (index) => {
      setOpen(false);
      // setSelectedImage(null)
      setSelectedImageIndex(index);
    };

    const handleNext = () => {
      if (selectedImageIndex < portFolioImage.length - 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      }
    };

    const handlePrev = () => {
      if (selectedImageIndex > 0) {
        setSelectedImageIndex(selectedImageIndex - 1);
      }
    };
  












    const fetchPortfolio = async () => {
        const result = await getData('api-category?is_feature=1')
        if (result.status) {
          setIsShimmer(false)
            setPortFolioImage(result.data)
            //console.log(result.data)
        }
    }

    const fetchAllData = async () => {
      const result = await getData('api-listing/1')
      if (result.status) {
          setPortFolioData(result.data)
          console.log(result.data)
      }
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
        createSession().then(() => {
          setTimeout(()=>(
            fetchAllData()
          ),5000)
        })
    }
    fetchAllData()
}, [])

    

    const createSession = async () => {
        const body = { email: "9907026112", password: "12345678" }
        const result = await postData("login", body)
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token)
        }
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            createSession().then(() => {
                fetchPortfolio()
            })
        }
        fetchPortfolio()
    }, [])


    // const showPortfolioImage = () => {
    //     return portFolioImage?.map((item) => {
    //         return (<div> <img src={item.photo}

    //     // return images?.map((item)=>{
    //     //     return (<div> <img src={`${serverURL}/images/${item}`} 
    //             style={{
    //                 width:"50%",

    //                 borderRadius: 30,
    //                 height: 'auto',

    //                 display: "block",
                   
    //                 aspectRatio: 2 / 2
    //             }} />

                

    //         </div>
    //         )
    //     })
    // }

    // const showPortfolioImage = () => {
    //     return portFolioImage?.map((item) => {


    //     return( <div  style={{ display: "flex", justifyContent: "center" }}>
    //         <img src={item.photo}
    //           style={{ width: 150, borderRadius: 0, height: "auto" ,aspectRatio:3/3,flexWrap:'wrap'}}
    //         />
    //       </div>)
       
    // })
        
    //   };


    const handleClickDialog=()=>{
      return( <Dialog
        maxWidth={"lg"}
        
       
       
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><div style={{fontSize:25,fontWeight:'bold',fontFamily:'kanit',color:'#e72e77',padding:25}}>{portFolioImage[selectedImageIndex]?.name} </div></DialogTitle>
        <DialogContent>
        {/* <Box style={{}}>
          {selectedImage && (
            <img 
              src={selectedImage} 
              alt="Selected" 
              style={{ width: '100%',height: '50%'}}
            />
          )}
        </Box> */}
           <div style={{zIndex:2,top:'50%', position:'absolute',display:'flex',alignItems:'center',justifyContent:'center',width:50,height:50,borderRadius:20,opacity:0.6}}>
                <ArrowBackIosIcon  fontSize="large" onClick={handlePrev} />
            </div>

<Box display="flex" justifyContent="center" alignItems="center">
          {portFolioImage[selectedImageIndex] && (
            <img 
              src={portFolioImage[selectedImageIndex].photo} 
              alt={`Image ${selectedImageIndex + 1}`} 
              style={{ width: 450, height: 300,aspectRatio:2/2 }}
            />

        //     <ReactImageMagnify
        //     {...{
        //         smallImage: {
        //             alt: portFolioImage[selectedImageIndex].name,
        //             isFluidWidth: true,
        //             src: portFolioImage[selectedImageIndex].photo,
        //         },
        //         largeImage: {
        //             src: portFolioImage[selectedImageIndex].photo,
        //             width: 1200,
        //             height: 1200,
        //         },
        //         enlargedImageContainerStyle: {
        //             background: '#fff',
        //             zIndex: 10,
        //         },
        //         enlargedImageStyle: {
        //             objectFit: 'cover',
        //         },
        //         isHintEnabled: true,
        //         shouldUsePositiveSpaceLens: true,

               
        //     }}
        //     style={{ width: 50, height: 100, aspectRatio: 2 / 2 }}
           
        // />
          )}
        </Box>
        <div style={{zIndex:2,top:'50%',right:'2.7%', position:'absolute',display:'flex',alignItems:'center',justifyContent:'center',width:40,height:40,borderRadius:20,opacity:0.6}}>
      <ArrowForwardIosIcon  fontSize="large" onClick={handleNext}/>
      </div>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose}>Close</Button>
          
        </DialogActions>
      </Dialog>)
    }

    

     const imagesToShow = portFolioImage.slice(0,12)


    return (
      <Box style={{width: '100%',height:matchesMd?<></>:'auto',fontFamily:'kanit'}} >
        <Grid container spacing={1}>
          
        {isShimmer?<> <ShimmerSimpleGallery card imageHeight={200}  col={4} row={4} gap={30} /></>:  imagesToShow.map((item,index) => (
            <Grid 
              item 
              xs={6} sm={2}  // 6 columns on xs, 3 columns on sm and larger
              key={item.photo} 
              onClick={() => handleClickOpen(index)}
              style={{ display: 'flex'}}
            >

              

              <Paper elevation={3} sx={{ padding:1 }}>
                <img 
                  src={item.photo} 
                  alt="Portfolio" 
                  style={{ width: '100%', height: 'auto', cursor: 'pointer', objectFit: 'cover' ,aspectRatio:2/2}}
                />
              </Paper>
              
</Grid>



          ))}
         

         


        </Grid>
        {handleClickDialog()}
        {matchesMd?<></>:
        <Grid item xs={12} style={{width:'100%',height:'30%',color:'#000',display:'flex',justifyContent:'center',alignItems:'center'}}>
          
        <div onClick={()=>navigate('/portfolioviewmore')} variant="outlined" style={{width:matchesMd?80:90,height:35,background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : '#fff',display:'flex',color:isPinkHovered ?'white':'#e72e77',fontSize:12,alignItems:'center',justifyContent:'center',marginTop:30,fontWeight:'bold',borderRadius:30,border:'solid 1px',cursor:'pointer'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>View More</div>
          
          
          </Grid>
}


      </Box>
      
    );











   
}