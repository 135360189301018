import { useState } from "react";

export default function ExtraPage(){
    const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [data, setData] = useState([
    // Sample data
    { id: 1, name: 'Apple', category: 'fruit' },
    { id: 2, name: 'Carrot', category: 'vegetable' },
    { id: 3, name: 'Banana', category: 'fruit' },
    { id: 4, name: 'Broccoli', category: 'vegetable' },
  ]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const filteredData = data.filter((item) => {
    return (
      (selectedFilter === 'all' || item.category === selectedFilter) &&
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });


    
  
  return (
    <div>
      <h1>Search and Filter</h1>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <select value={selectedFilter} onChange={handleFilterChange}>
        <option value="all">All</option>
        <option value="fruit">Fruits</option>
        <option value="vegetable">Vegetables</option>
      </select>
      <ul>
        {filteredData.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
    </div>
  );



   
}