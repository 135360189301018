import { showStyles } from "./ShowListCss";
import { Paper } from "@mui/material";
 export default function VendorList(props){

    return(<Paper elevation={2} style={{display:props?.isOpen?'flex':'none',position:'absolute',top:50,zIndex:3,marginLeft:400,cursor:'pointer',paddingBottom:30,paddingTop:20,width:'50%',height:'500%'}}>


VendorList


    </Paper>


    )
 }