import { useSelector } from "react-redux";
import { Divider,Paper,Grid, Input, MenuItem } from "@mui/material";
import { showStyles } from "./ShowListCss";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { getData,postData,serverURL } from "../../Services/FetchNodeServices";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Select} from "@mui/material";

import Radio from '@mui/material/Radio';
import Swal from "sweetalert2"


export default function ShowFilterList(props)

{ 
 
 
 

  const showClasses=showStyles();
var navigate=useNavigate()

const[guestFilter,setGuestFilter]=useState('')
const[pricePlate,setPricePlate]=useState('')
const[space,setSpace]=useState([])
const[renaltCost,setRenaltCost]=useState('')
const[parking,setParking]=useState('')
const[categoryId,setCategoryId]=useState(props.data.category_id)
const[subCategoryId,setSubCategoryId]=useState(props.data.subcategory_id)
const[id,setId]=useState(props.data.id)
const[roomCount,setRoomCount]=useState([])
  const [isPinkHovered, setIsPinkHovered] = useState(false);
    const [isGreenHovered, setIsGreenHovered] = useState(false);
     const [loading, setLoading] = useState(false); 
      const [isShimmer, setIsShimmer] = useState(true);
       const [filters, setFilters] = useState([]);
     
  
  




const fetchAllFilter = async () => {
  setLoading(true);
  const result = await getData('api-listing-filter?subcategory_id=1')
  if (result.status) {
   
    setFilters(result.data)
    setIsShimmer(false)
   
     // console.log(result.data)
  }
  setLoading(false);
}




const handleSubmit = async () => {
  const filtersData = constructFiltersData();
  
  console.log('Filters data to be submitted:', JSON.stringify(filtersData, null, 2));

  try {
    const result = await postData('api-listing-filter-api', filtersData);
    
    console.log('API response:', result);
    
    if (result.status) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Filters submitted...",
        showConfirmButton: false,
        timer: 1500,
        toast: true
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to submit filters",
        showConfirmButton: false,
        timer: 1500,
        toast: true
      });
    }
  } catch (error) {
    console.error("Error submitting filters data:", error);
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: "Failed to submit filters",
      showConfirmButton: false,
      timer: 1500,
      toast: true
    });
  }
};
  


  

// const handleSubmit=async()=>{

//   {      var body={categoryid:categoryId,subcategoryid:subCategoryId,attr:{id:[guestFilter],optionType:[pricePlate],id:[space],optionType:[renaltCost],optionType:[parking]},"low_price":null,"high_price":null}
//         var result=await postData('users/submit_user',body)
//        if(result.status)
//        {
//           Swal.fire({
//               position: "top-end",
//               icon: "success",
//               title: "Filters submit....",
//               showConfirmButton: false,
//               timer: 1500,
//               toast:true
//             });

           
//        }

      
    
// }
// }








const createSession = async () => {
  setLoading(true)
  const body = { email: "9907026112", password: "12345678" }
  const result = await postData("login", body)
  if (result.token_status) {
      localStorage.setItem("token", result.data.user.jwt_token)
  }
  setLoading(false);
}

useEffect(() => {
if (!localStorage.getItem("token")) {
    createSession().then(() => {
      fetchAllFilter()
    })
}
fetchAllFilter()
}, [])




const handleCheckboxChange = (event, setStateFunction) => {
  const { value, checked } = event.target;
  setStateFunction(prev => {
    if (checked) {
      return [...prev, value];
    } else {
      return prev.filter(item => item !== value);
    }
  });
};

const handleRadioChange = (event, setStateFunction) => {
  setStateFunction(event.target.value);
};

const handleInputChange = (event, setStateFunction) => {
  setStateFunction(event.target.value);
};

const handleSelectChange = (event, setStateFunction) => {
  setStateFunction(event.target.value);
};






const findFilter = (name) => filters.find(filter => filter.name === name);

const renderControl = (parentFilterType, option, setStateFunction) => {
  if (!parentFilterType) {
    return null;
  }
  
  switch (parentFilterType) {
    case 'checkbox':
      return (
        <Checkbox
          value={option.name}
          onChange={(event) => handleCheckboxChange(event, setStateFunction)}
        />
      );
    case 'radio':
      return (
        <Radio
          value={option.name}
          onChange={(event) => handleRadioChange(event, setStateFunction)}
        />
      );
    case 'input':
      return (
        <Input
          value={option.name}
          onChange={(event) => handleInputChange(event, setStateFunction)}
        />
      );
    case 'dropdown':
      return (
        <Select
          value={option.find(opt => opt.selected)?.name || ''}
          onChange={(event) => handleSelectChange(event, setStateFunction)}
        >
          {option.map((opt, optIndex) => (
            <MenuItem key={optIndex} value={opt.name}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      );
    default:
      return null;
  }
};


const numberOfGuestFilter = findFilter("No of Guest");
  const numberOfPricePlate = findFilter("Price per Plate");
  const numberOfSpace = findFilter("Space");
  const numberOfRenatlcoast = findFilter("Renatl coast");
  const Parking = findFilter("Parking");
  const numberOfroomcount = findFilter("room count");







  const constructFiltersData = () => {
    return {
      category_id: categoryId,
      subcategory_id: subCategoryId,
      attr: [
        {
          id: id,
          optionType: 'checkbox',
          name: 'No of Guest',
          options: guestFilter ? [{ id: 1, name: guestFilter, optionType: 'checkbox', label: null }] : []
        },
        {
          id: id,
          optionType: 'radio',
          name: 'Price per Plate',
          options: pricePlate ? [{ id: 5, name: pricePlate, optionType: 'input', label: 'Non-veg Price' }] : []
        },
        {
          id: id,
          optionType: 'checkbox',
          name: 'Space',
          options: space.map((s, index) => ({
            id: 12 + index,
            name: s,
            optionType: 'checkbox',
            label: null
          }))
        },
        {
          id: id,
          optionType: 'input',
          name: 'Renatl cost',
          options: renaltCost ? [{ id: 7, name: renaltCost, optionType: null, label: null }] : []
        },
        {
          id: id,
          optionType: 'dropdown',
          name: 'Parking',
          options: parking ? [{ id: 8, name: parking, optionType: null, label: null }] : []
        },
        {
          id: id,
          optionType: 'checkbox',
          name: 'room count',
          options: roomCount.map((r, index) => ({
            id: 16 + index,
            name: r,
            optionType: null,
            label: null
          }))
        }
      ],
      low_price: null,
      high_price: null
    };
  };



    
    return(<Paper elevation={2} style={{display:props?.isOpen?'flex':'none',position:'absolute',zIndex:3,marginLeft:75,cursor:'pointer',paddingBottom:30,paddingTop:20,width:'90%',height:'80%'}}>
     
    

        <Grid container spacing={1}>

        
         <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', fontFamily: 'kanit', color: '#e72e77', fontSize: 18}}>
          <Grid item xs={1} style={{color:'#000',alignItems:'center',margingTop:15,padding:5,marginLeft:50}}>
        

           {numberOfGuestFilter && numberOfGuestFilter.options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                 <FormControlLabel
                  size="small"
                  control={renderControl(numberOfGuestFilter.optionType, option, setGuestFilter)}
                  label={option.name}
                />
              </div>
            ))}


           
           
          </Grid>

          <Grid item xs={1} style={{color:'#000',alignItems:'center',margingTop:15,padding:5,marginLeft:80}}>
          
          
          {numberOfPricePlate && numberOfPricePlate.options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                 <FormControlLabel
                  size="small"
                  control={renderControl(numberOfPricePlate.optionType, option, setPricePlate)}
                  label={option.name}
                />
              </div>
            ))}
           
          </Grid>


          <Grid item xs={1} style={{color:'#000',alignItems:'center',margingTop:15,padding:5,marginLeft:80}}>

          {numberOfSpace && numberOfSpace.options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
               <FormControlLabel
                  size="small"
                  control={renderControl(numberOfSpace.optionType, option, setSpace)}
                  label={option.name}
                />
              </div>
            ))}
           
          </Grid>


          <Grid item xs={1} style={{ color: '#000', alignItems: 'center', margingTop: 100, padding: 15, marginLeft: 35 }}>
            {numberOfRenatlcoast && numberOfRenatlcoast.options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  size="small"
                  control={renderControl(numberOfRenatlcoast.optionType, option, setRenaltCost)}
                  label={option.name}
                />
              </div>
            ))}
          </Grid>

         
          <Grid item xs={1} style={{ color: '#000', alignItems: 'center', margingTop: 15, padding: 5, marginLeft: 74 }}>
  {Parking && (
    <FormControlLabel
      size="small"
      control={renderControl(Parking.optionType, Parking.options, setParking)}
      label="Parking"
    />
  )}
</Grid>


<Grid item xs={1} style={{ color: '#000', alignItems: 'center', margingTop: 15, padding: 5, marginLeft: 55 }}>
            {numberOfroomcount && numberOfroomcount.options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  size="small"
                  control={renderControl(numberOfroomcount.optionType, option, setRoomCount)}
                  label={option.name}
                />
              </div>
            ))}
          </Grid>


          
        </Grid>



        <Grid item xs={12} style={{display:'flex ' ,justifyContent:'center'}}>
              
                  <div  style={{width:'10%',margin:10,height:'25%',color:'#fff',borderRadius:30,justifyContent:'center',alignItems:'center',display:'flex', background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : 'linear-gradient(to bottom,#db84a6, #e72e77)'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}> Reset</div>
            

                
                <div onClick={()=>handleSubmit()} style={{width:'10%',margin:10,height:'25%',color:'#fff',borderRadius:30,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)'}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}> Submit</div>
        
      </Grid>
        


       
</Grid>


    </Paper>)
}