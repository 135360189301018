import { Grid,Paper ,Divider, TextField} from "@mui/material"
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Typography from '@mui/material/Typography';
import {useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import { useState,useEffect } from "react";
import { serverURL,postData,getData } from "../../Services/FetchNodeServices";
import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate } from "react-router-dom";

export default function ReviewComponent(){
  var navigate=useNavigate()
  const [isPinkHovered, setIsPinkHovered] = useState(false);
   const [portfolioData, setPortFolioData] = useState([]);
  const [isGreenHovered, setIsGreenHovered] = useState(false);



  const theme = useTheme();

  const matchesMd=useMediaQuery(theme.breakpoints.down("md"));


  const fetchAllData = async () => {
    const result = await getData('api-listing/1')
    if (result.status) {
        setPortFolioData(result.data)
     
    }
}

useEffect(() => {
  if (!localStorage.getItem("token")) {
      createSession().then(() => {
          fetchAllData()
      })
  }
  fetchAllData()
}, [])

  

  const createSession = async () => {
      const body = { email: "9907026112", password: "12345678" }
      const result = await postData("login", body)
      if (result.token_status) {
          localStorage.setItem("token", result.data.user.jwt_token)
      }
  }

 


    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#ff6d75',
        },
      });

      const CustomizedRating=()=> {
        return (
          <Box
            sx={{
              '& > legend': { mt: 2 },
            }}
          >
            <Typography component="legend" style={{fontSize:12}}>Please Rate us How much you Like This Website</Typography>
            <StyledRating
              name="customized-color"
              defaultValue={2}
              getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={0.5}
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
            <Typography component="legend" style={{fontSize:12}}>10 stars</Typography>
            <Rating name="customized-10" defaultValue={2} max={10} />
          </Box>
        );
      }


    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
      };
    
      const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
      };

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        box-sizing: border-box;
        width: 95%;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
      );

    return(<Paper style={{display:'flex',width:matchesMd?'100%':'93%',height:'100%',fontFamily:'kanit',padding:matchesMd?1:20,margin:matchesMd?0:20}}>
        <Grid container spacing={3}>

        <Grid item xs={12} style={{alignItems:'center',display:'flex',fontSize:matchesMd?'0.8rem':20,fontWeight:'bold',marginLeft:matchesMd?5:<></>}}>
                Reviews for Venus Banquet,Gwalior
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} md={5}>

            <Grid item xs={12}  style={{fontSize:15,fontWeight:'bold',marginLeft:matchesMd?12:<></>}} >
              Rating Distributions
              </Grid>

                <Grid item xs={12}  style={{marginLeft:matchesMd?12:<></>}} >
              {CustomizedRating()}
              </Grid>

             
            </Grid>

            <Grid item md={7} xs={12} >
                <Grid item xs={12} style={{fontSize:15,fontWeight:'bold',marginLeft:'3%'}}>
                    Review Venus Banquet,Gwalior
                </Grid>

                <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:30,lineHeight:'26px'}}>
                    <Textarea  style={{display:'flex'}}  minRows={7} margin="normal" placeholder="Tell us about your experience"/>
                   
                </Grid>

                <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:5}}>
                    <TextField  style={{display:'flex',width:matchesMd?'95%':640}}   margin="normal" placeholder="How much did you spend on this vendor"/>
                   
                </Grid>
             
             </Grid>

             <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={12}>
            <span style={{fontSize:15,fontWeight:'bold',marginLeft:matchesMd?12:<></>}}>Total Reviews: {portfolioData?.reviews?.Total_review}</span>
            </Grid>

            
            <Grid item xs={12} style={{display:'flex',alignItems:'center',marginRight:5}}>
             
              <Avatar src="/broken-image.jpg"  style={{width:35,height:35,display:'flex'}}/>
              <span style={{display:'flex',padding:10,fontWeight:'bold'}}>{portfolioData?.reviews?.review_list[0]?.name}</span>
              <div  style={{width:matchesMd?'12%':'4%',height:'53%',color:'#fff',borderRadius:3,justifyContent:'center',alignItems:'center',display:'flex', background: isGreenHovered ? 'linear-gradient(to bottom, #3e8e41, #2c6e30 )' : 'linear-gradient(to bottom,#97cd9a, #3e8e41)'}} onMouseEnter={() => setIsGreenHovered(true)} onMouseLeave={() => setIsGreenHovered(false)}><StarsIcon fontSize="small"/><span style={{fontSize:15,padding:3}} >{portfolioData?.reviews?.review_list[0]?.rating}</span> </div>
              <div onClick={()=>navigate('/reviewsviewmore')} variant="outlined" style={{width:matchesMd?80:90,height:35,background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d )' : '#fff',display:'flex',color:isPinkHovered ?'white':'#e72e77',fontSize:12,alignItems:'center',justifyContent:'center',marginBottom:20,fontWeight:'bold',borderRadius:30,border:'solid 1px',cursor:'pointer',marginLeft:'auto'}} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>View More</div>
              </Grid>

              <Grid item xs={12} style={{display:'flex',alignItems:'center',fontSize:15,paddingBottom:10}}>
              <span >{portfolioData?.reviews?.review_list[0]?.message}</span>
             </Grid>
             
            

              

            


        </Grid>

    </Paper>)
}