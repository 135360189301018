
import { Divider, Grid,Paper,Button , useMediaQuery} from "@mui/material"
import InputBase from '@mui/material/InputBase';
import { useEffect,useState } from "react";
import { getData ,postData} from "../../Services/FetchNodeServices";
import {MenuItem} from "@mui/material"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


export default function FilterComponent(props){
    const[categoryList,setCategoryList]=useState([])
    const[brandList,setBrandList]=useState([])
    const [subCategoryList, setSubCategoryList] = useState([]);
    const[categoryId,setCategoryId]=useState('')
    const [filteredSubCategoryList, setFilteredSubCategoryList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const[anchorE1,setAnchorEl]=useState('')
    const [expanded, setExpanded] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);

   

    const theme = useTheme();
    var navigate=useNavigate()

   const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

   var category_id=localStorage.getItem('category')
   console.log('00000',category_id)
   

  
   







   const fetchAllSubCategory = async() => {
    
    const result = await getData(`api-subcategory?category_id=${category_id}`);
    if (result.status) {
      setSubCategoryList(result.data);
      console.log('xxxxxxx',result.data)
    }
   
  };


  useEffect(() => {
    if (category_id) {
      fetchAllSubCategory(category_id);
    }
  }, [category_id]);



  const fillAllSubCategory = () => {
    return subCategoryList?.map((item) => {
      return <MenuItem value={item.id}>{item.name}</MenuItem>;
    });
  };
    


  const handleFilteredSubcategory = (item) => {
    navigate('/shopscreen', {
      state: {
        item: item,
        category_id: category_id  // explicitly passing category_id
      }
    });
  };

  useEffect(() => {
    console.log('Category ID from local storage:', category_id);
  }, [category_id]);







 useEffect(() => {
    const filteredSubCategories = subCategoryList?.filter(subcategory =>
        subcategory.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredSubCategoryList(filteredSubCategories);
}, [searchQuery, subCategoryList]);


    const showAllCategory=()=>{
        return filteredSubCategoryList?.map((item)=>{
         
         return <div key={item.id}>
        <Accordion 
            onChange={(event, isExpanded) => {
              setExpanded(isExpanded ? item.id : false);
               }}>
       
        <AccordionSummary onClick={()=>handleFilteredSubcategory(item)} >
          {item.name}
        </AccordionSummary>
       
      </Accordion>
    </div>


        })
    }


    const handleClearFilter = () => {
      navigate('/shopscreen', {
          state: {
              category_id: category_id  // Pass the category_id only, no subcategory
          }
      });
      setSearchQuery(''); // Optional: Clear the search query as well
  };
  
    
    return(<div style={{width:'100%',height:'100%'}}>

      
   <div  style={{width:matchesMd?<></>:280,height:'auto', background:" linear-gradient(45deg, rgba(255, 165, 0, 1), rgba(255, 192, 203, 1))",padding:matchesMd?'':10,margin:matchesMd?'':20,fontFamily:'kanit',borderRadius:10,opacity:0.8}}>
    <Grid container spacing={1}>
    <Grid item xs={12} style={{ fontSize: 20, fontWeight: 'bolder', marginTop: 10, padding: 5, marginLeft: 10,  display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
  <div>Filters</div>

  <div onClick={() => handleClearFilter()} variant="outlined" style={{ width: matchesMd ? 80 : 90, height: 35, background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d)' : '#fff', display: 'flex', color: isPinkHovered ? 'white' : '#e72e77', fontSize: 12, alignItems: 'center', justifyContent: 'center', marginTop: 0, fontWeight: 'bold', borderRadius: 30, border: 'solid 1px', cursor: 'pointer' }} onMouseEnter={() => setIsPinkHovered(true)} onMouseLeave={() => setIsPinkHovered(false)}>
    Clear Filter
  </div>
</Grid>


       

        <Grid item xs={12}>
            <Divider/>
        </Grid>


        <Grid item xs={6} style={{fontSize:18,marginTop:10,padding:5,marginLeft:10,borderWidth:'bolder'}}>
           Sub Categories
        </Grid>

        <Grid item xs={12}>
        <Paper
           
          
           component="form"
           sx={{  display: 'flex',  alignItems: 'center', width: '95%',borderRadius:5}}
         >
         
           <InputBase

           style={{color:'black'}}
           
             sx={{ ml: 1, flex: 1 }}
             placeholder="Search Sub Categories"
             inputProps={{ 'aria-label': 'search google maps' }}
             value={searchQuery}
             onChange={(e) => setSearchQuery(e.target.value)}
           />
           
           </Paper>
           </Grid>

           <Grid item xs={12} >
           <div style={{display:'flex',flexDirection:'column'}}>{showAllCategory()}</div>
           </Grid>

           <Grid item xs={12}>
            <Divider/>
        </Grid>

       

           <Grid item xs={12} >
           <div style={{display:'flex',flexDirection:'column'}}></div>
           </Grid>

          


         


        
          
        





    </Grid>




</div>
    </div>)
}