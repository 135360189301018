import { Paper,useMediaQuery ,Button} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ShowFilterList from "./ShowFilterList";
import { useState,useEffect } from "react";
import { getData,postData } from "../../Services/FetchNodeServices";


export default function UpperFilter(props){
var data=props?.item






    const theme = useTheme();
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    
    const [loading, setLoading] = useState(false); 
    const[catgoryData,setCategoryData]=useState([])
  const [isShimmer, setIsShimmer] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState([]);
   
  const showFilterDetails=()=>{
        
        setIsOpen(true)
      }

      const hideFilterDetails=()=>{
        setIsOpen(false)
      }

      const hideBoxMenu=()=>{
        hideFilterDetails()
       
        
      }


      const fetchAllFilter = async () => {
        setLoading(true);
        const result = await getData('api-listing-filter?subcategory_id=1')
        if (result.status) {
         
          setFilters(result.data)
          setIsShimmer(false)
         
           // console.log(result.data)
        }
        setLoading(false);
    }
  
  
     
    
  
    
    
    
    const createSession = async () => {
        setLoading(true)
        const body = { email: "9907026112", password: "12345678" }
        const result = await postData("login", body)
        if (result.token_status) {
            localStorage.setItem("token", result.data.user.jwt_token)
        }
        setLoading(false);
    }
    
    useEffect(() => {
      if (!localStorage.getItem("token")) {
          createSession().then(() => {
            fetchAllFilter()
          })
      }
      fetchAllFilter()
  }, [])

return(<Paper  onMouseLeave={()=>hideBoxMenu()}>
<div style={{width:'100%',height:40,background:'#fff',display:'flex',flexDirection:'row'}}>
   <span><Button    onMouseOver={()=>hideBoxMenu()}  ></Button></span> 
   
{/* <div onMouseOver={showFilterDetails}  style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:60,fontSize:matchesMd?'0.7em':<></>}}>No. of Guests <ArrowDropDownIcon/></div>
<div  onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:45,fontSize:matchesMd?'0.7em':<></>}}>Room Count <ArrowDropDownIcon/> </div>
<div  onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>Price per plate <ArrowDropDownIcon/> </div>
<div  onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>Rental Cost <ArrowDropDownIcon/> </div>
<div    onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>Venue Type <ArrowDropDownIcon/> </div>
<div  onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>Rating  <ArrowDropDownIcon/></div> 
<div onMouseOver={showFilterDetails} style={{color:'#000',display:'flex',alignItems:'center',margingTop:15,padding:5,marginLeft:35,fontSize:matchesMd?'0.7em':<></>}}>Space <ArrowDropDownIcon/></div> */}




{filters?.map((item, index) => (
      <div
        key={index}
        onMouseOver={showFilterDetails}
        style={{
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          marginTop: 5,
          justifyContent:'space-between',
          marginLeft: 60,
          fontSize: matchesMd ? '0.7em' : '1em',
        }}
      >
        {item.name} <ArrowDropDownIcon />
      </div>
    ))}



   
   
    </div>
   
   
   
    <ShowFilterList isOpen={isOpen} data={data}/>
    </Paper>)
}